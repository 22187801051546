import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { sendCorrectedMail4DPO } from "../../api/scoring";
import { LoaderResponse } from "../../components/Loader";
import { COLORS } from "../../constants/ColorsData";
import { HIST_DATA4EVAL, SERVICE_VALUE_KEY } from "../../constants/StoreKey";
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import "../../styles/SlmTestEmailForm.css";
import { decryptData, encryptData } from "../../utils/util_function";

import { SelectChangeEvent } from '@mui/material/Select';


interface MailData {
    companyName: string;
    correctedMailTitle: string;
    correctedMail: string;
    generatedMail: string;
    generatedMailTitle: string;
    personJob: string;
    postCategory: string;
    postSummary: string;
    senderCompanyName: string;
    senderJob: string;
    senderName: string;
    senderValueProposition: string;
    targetCompanyActivity: string;
    targetCompanyValue: string;
}

var default_mail_segment_key = "accroche"
var default_criteria = "pertinence"
var default_question = "L’accroche mentionne-t-elle le post que la personne a publié sur LinkedIn ?"


const SLMTestEmailForm = () => {
    // Initialisation des états avec des valeurs par défaut
    const [formData, setFormData] = useState({
        companyName: "",
        correctedMail: "",
        correctedMailTitle: "",
        generatedMail: "",
        generatedMailTitle: "",
        mailContext: "",
        mailId: "",
        mailSegment: {
            salutation: "",
            accroche: "",
            transition: "",
            service_value: "",
            cta: "",
        },
        mailSegmentKey: default_mail_segment_key,
        personJob: "",
        priorite: "1",
        postCategory: "",
        postSummary: "",
        targetCompanyActivity: "",
        targetCompanyValue: "",
        selectedCategory: "Contenu professionnel",
        selectedCriteria: default_criteria,
        selectedQuestion: default_question,
        selectedSubcategory: "Conseils et astuces",
        senderCompanyName: "",
        senderJob: "",
        senderName: "",
        senderValueProposition: "",
    });

    // Field empty or not
    const [fieldState, setFieldState] = useState({
        generatedMailTitle: true,
        generatedMail: true,
        correctedMailTitle: true,
        correctedMail: true,
        mailContext: true,
        senderValueProposition: true,
        personJob: true,
        postCategory: true,
        postSummary: true,
        companyName: true
    })

    const categories: Array<string> = [
        "Post publié",
        "Post republié",
        "Post partagé",
        "Description profil",
        "Valeur & service vendu"
    ]

    const [runLoader, setRunLoader] = useState(false)
    const [disableSendButton, setDisableSendButton] = useState(true)
    const [hideAll, setHideAll] = useState(false)

    const handleChangeRecipient = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        switch (name) {
            case "personJob":
                setFieldState(prev => ({ ...prev, personJob: value ? false : true }))
                break
            case "companyName":  // Fixed property name to match input field
                setFieldState(prev => ({ ...prev, companyName: value ? false : true }))
                break
            case "targetCompanyActivity":  // Fixed property name
                // setFieldState(prev => ({...prev, targetCompanyActivity: value ? false : true}))
                break
            default:
                break
        }
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;

        if (name === "generatedMail") {
            const mail_split_data = value.split("\n\n")

            const salutation = mail_split_data[0] || ""
            const accroche_transition = mail_split_data[1] || ""
            const proposition = mail_split_data[2] || ""
            const cta = mail_split_data[3] || ""

            setFormData((prev) => ({
                ...prev,
                generatedMail: value,
                mailSegment: {
                    salutation: salutation,
                    accroche: accroche_transition.split(".")[0] || "",
                    transition: accroche_transition.split(".")?.[1] || "",
                    service_value: proposition,
                    cta: cta
                }
            }))
        } else {
            if (name === "senderValueProposition") encryptData(SERVICE_VALUE_KEY, value)
            setFormData((prev) => ({ ...prev, [name]: value }))
        }
        setFieldState((prev) => ({ ...prev, [name]: value ? false : true }))
    }

    const handleChangeContextType = (
        e: SelectChangeEvent
    ) => {
        const { name, value } = e.target;

        setFormData((prev) => ({ ...prev, postCategory: value as string }));
        setFieldState((prev) => ({ ...prev, [name]: value ? false : true }))
    }

    const handleMailDecomposition = (generatedMail: string): {
        salutation: string;
        accroche: string;
        transition: string;
        service_value: string;
        cta: string;
    } => {
        let paragraphs: string[] = [];

        if (generatedMail.includes("\n\n")) {
            paragraphs = generatedMail
                .split("\n\n")
                .map((p) => p.trim())
                .filter((p) => p.length > 0);
        } else {
            paragraphs = generatedMail
                .split("\n")
                .map((p) => p.trim())
                .filter((p) => p.length > 0);
        }

        let salutation = "";
        let accroche = "";
        let transition = "";
        let service_value = "";
        let cta = "";

        if (paragraphs.length > 0) {
            salutation = paragraphs[0];
        }

        if (paragraphs.length > 1) {
            const part = paragraphs[1];
            const separatorIndex = part.indexOf(". ");
            if (separatorIndex !== -1) {
                accroche = part.substring(0, separatorIndex + 1).trim();
                transition = part.substring(separatorIndex + 1).trim();
            } else {
                accroche = part;
            }
        }

        if (paragraphs.length > 2) {
            service_value = paragraphs[2];
        }

        if (paragraphs.length > 3) {
            cta = paragraphs[3];
        }

        return { salutation, accroche, transition, service_value, cta };
    };


    const handleSubmit = () => {
        setRunLoader(true)

        const data2send = {
            categorie: formData.postCategory,
            companyName: formData.companyName,
            contexte: formData.mailContext,
            resumeContexte : formData.postSummary,
            key : "body",
            mailId: formData.mailId,
            personJob: formData.personJob,
            senderValueProposition: formData.senderValueProposition,
            targetCompanyActivity: formData.targetCompanyActivity,
            body: {
                chosen: formData.correctedMail,
                rejected: formData.generatedMail
            },
            title: {
                chosen: formData.correctedMailTitle,
                rejected: formData.generatedMailTitle
            },
            mail_segment: {
                chosen: handleMailDecomposition(formData.correctedMail),
                rejected: handleMailDecomposition(formData.generatedMail)
            },
        };

        // Sauvegarder les données dans localStorage à chaque changement
        encryptData(HIST_DATA4EVAL, JSON.stringify(formData));

        // console.log("Data 2 send for DPO: ", data2send)
        // return
        sendCorrectedMail4DPO(data2send).then((rep: any) => {
            const response_data_body = rep.data.body;
            // console.log("Backend dpo response : ", response_data_body)
            if (response_data_body) {
                alert("Correction sauvegardée")
                localStorage.removeItem(HIST_DATA4EVAL)
                Redirect2NewPage("Aurélia", `Dashboard/CampaignHistory`)
            }

            setRunLoader(false);
        }).catch((error) => {
            console.error("Erreur lors de l'envoi :", error);
            setRunLoader(false);
        });
    };

    /**
     * Vérifier si les champs ont déjà une valeur
     * @param data 
     */
    const localDataValueVerification = (data: MailData) => {
        setFieldState({
            generatedMailTitle: !data.generatedMailTitle.trim(),
            generatedMail: !data.generatedMail.trim(),
            correctedMailTitle: !data.correctedMailTitle.trim(),
            correctedMail: !data.correctedMail.trim(),
            mailContext: !data.companyName.trim(),
            senderValueProposition: !data.senderValueProposition.trim() ? true : false,
            personJob: !data.personJob.trim(),
            postCategory: !data.postCategory.trim(),
            postSummary: !data.postSummary ? true : false,
            companyName: !data.companyName.trim()
        })
    }

    const disableAllquestions = () => {
        // Red flag du mail : on met à jour la bdd sans insérer aucune données pour le dataset DPO
        sendCorrectedMail4DPO({
            mailId: formData.mailId,
            key : "redflag"
        }).then((rep: any) => {
            const response_data_body = rep.data.body;
            // console.log("Backend dpo response : ", response_data_body)
            if (response_data_body) {
                setHideAll(!hideAll)
                localStorage.removeItem(HIST_DATA4EVAL)
                Redirect2NewPage("Aurélia", `Dashboard/CampaignHistory`)
            }

            setRunLoader(false);
        }).catch((error) => {
            console.error("Erreur lors de l'envoi :", error);
            setRunLoader(false);
        });
    }

    // Chargement depuis localStorage et initialisation des questions
    useEffect(() => {
        const parsedData = decryptData(HIST_DATA4EVAL);
        if (parsedData) {
            try {
                // Vérifier si la proposition de valeur est vide, mais déjà rempli depuis la page émetteur
                if(parsedData.senderValueProposition === "") {
                    const ls_senderValue = decryptData(SERVICE_VALUE_KEY)
                    let updt_data = parsedData
                    updt_data['senderValueProposition'] = ls_senderValue
                    
                    setFormData(updt_data);
                    localDataValueVerification(updt_data)
                }
                else {
                    setFormData(parsedData)
                    localDataValueVerification(parsedData)
                }
            } catch (error) {
                console.error("Error parsing stored data:", error);
            }
        } else {
        }
    }, []);

    useEffect(() => {
        const hasTrueValue = () => Object.values(fieldState).some(value => value === true);
        
        if (!hasTrueValue()) setDisableSendButton(false)
    }, [fieldState])


    return (
        <div className="slm-main-container">
            <div className="slm-main-head">
                <h2>SLM : Création dataset DPO</h2>
            </div>
            <div className="slm-main-body">
                <div className="slm-main-title-part-one">
                    <div className="slm-lr-title-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3">Titre</h3>
                            {
                                // formData.generatedMailTitle && (
                                <Box className="box-form slm-box-container" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField id="outlined-basic" label="Titre généré" variant="outlined" fullWidth multiline
                                        name="generatedMailTitle" rows={1}
                                        color={fieldState.generatedMailTitle ? "error" : "success"}
                                        className={"no-error slm-input-text"}
                                        // className={true ? "error-field slm-input-text" : "no-error slm-input-text"}
                                        value={formData.generatedMailTitle}
                                        onChange={handleChange}
                                    // InputProps={{
                                    //     readOnly: true,
                                    // }}
                                    />
                                </Box>
                                // )
                            }
                        </div>
                    </div>

                    <div className="slm-lr-title-part">
                        <div className="slm-main-title-part-one">
                            <div className="slm-email-form-div">
                                <h3 className="slm-h3" style={{ color: "transparent" }}>Titre</h3>
                                {
                                    // formData && (
                                    <Box className="box-form slm-box-container" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                        <TextField
                                            color={fieldState.correctedMailTitle ? 'error' : 'success'}
                                            id="outlined-basic" label="Titre corrigé" variant="outlined"
                                            fullWidth multiline
                                            name="correctedMailTitle" rows={1}
                                            className={"no-error slm-input-text"}
                                            value={formData.correctedMailTitle}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    // )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slm-main-body-part-two">
                    <div className="slm-lr-body-part">
                        <div className="slm-email-form-div">
                            <div style={{ display: "flex", flex: 1 }}>
                                <h3 className="slm-h3">Corps de mail</h3>
                                <div style={{ display: "flex" }}>
                                    <input
                                        disabled={hideAll}
                                        type="checkbox"
                                        style={{ margin: 5, appearance: "auto", width: 25, accentColor: COLORS.rouge }}
                                        onChange={disableAllquestions}
                                    />
                                    <p id="redflag-text">Red Flag</p>
                                </div>
                            </div>
                            {
                                // formData.generatedMail && (
                                <Box className="box-form slm-box-container" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField
                                        color={fieldState.generatedMail ? 'error' : 'success'}
                                        id="outlined-multiline-static"
                                        label="Corps de mail généré"
                                        fullWidth
                                        multiline
                                        name="generatedMail"
                                        rows={10}
                                        value={formData.generatedMail}
                                        onChange={handleChange}         // Cette fonction ne sera pas appelée grâce à readOnly
                                        className={"no-error slm-input-text"}
                                    // InputProps={{
                                    //     readOnly: true,
                                    // }}
                                    />
                                </Box>
                                // )
                            }
                        </div>
                    </div>

                    <div className="slm-lr-body-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3" style={{ color: "transparent" }}>Corps de mail</h3>
                            {
                                // formData.generatedMail && (
                                <Box style={{ "paddingRight": 15 }} className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField
                                        color={fieldState.correctedMail ? 'error' : 'success'}
                                        id="outlined-multiline-static"
                                        label="Corps de mail corrigé"
                                        fullWidth
                                        multiline
                                        name="correctedMail"
                                        rows={10}
                                        value={formData.correctedMail}
                                        onChange={handleChange}         // Cette fonction ne sera pas appelée grâce à readOnly
                                        className={"no-error slm-input-text"}
                                    />
                                </Box>
                                // )
                            }
                        </div>
                    </div>
                </div>


                <div className="slm-main-title-part-one">
                    <div className="slm-lr-title-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3">Type de contexte</h3>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    color={fieldState.postCategory ? 'error' : 'success'}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="postCategory"
                                    value={formData.postCategory}
                                    label="Type de contexte"
                                    onChange={handleChangeContextType}
                                >
                                    {categories.map((cat, index) => (
                                        <MenuItem key={index} value={cat}>{cat}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    
                    <div className="slm-lr-title-part">
                        <div className="slm-email-form-div">

                        </div>
                    </div>
                </div>


                <div className="slm-main-body-part-two">
                    <div className="slm-lr-body-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3">Valeur du contexte</h3>
                            <Box className="box-form slm-box-container" component="form" sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }} noValidate autoComplete="off">
                                <TextField
                                    color={fieldState.mailContext ? 'error' : 'success'}
                                    id="outlined-multiline-static" label="Post, description, info site internet"
                                    fullWidth multiline
                                    name="mailContext" rows={10}
                                    value={formData.mailContext}
                                    onChange={handleChange}
                                    className={"no-error slm-input-text"} />
                            </Box>
                        </div>
                    </div>

                    <div className="slm-lr-body-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3">Résumé du post/description</h3>
                            <Box style={{ "paddingRight": 15 }} className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                <TextField
                                    color={fieldState.postSummary ? 'error' : 'success'}
                                    id="outlined-multiline-static"
                                    label="Contenu du résumé"
                                    fullWidth
                                    multiline
                                    name="postSummary"
                                    rows={10}
                                    value={formData.postSummary}
                                    onChange={handleChange}
                                    className={"no-error slm-input-text"}
                                />
                            </Box>
                        </div>
                    </div>
                </div>
                

                <div className="slm-lr-body-part">
                    <div className="slm-email-form-div">
                        <h3 className="slm-h3">Émetteur</h3>
                        <Box style={{ "paddingRight": 15 }} className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                            <TextField
                                color={fieldState.senderValueProposition ? 'error' : 'success'}
                                id="outlined-multiline-static"
                                label="Proposition de valeur"
                                fullWidth
                                multiline
                                name="senderValueProposition"
                                rows={4}
                                value={formData.senderValueProposition}
                                onChange={handleChange}
                                className={"no-error slm-input-text"}
                            />
                        </Box>
                    </div>
                </div>
    

                <div className="slm-main-title-part-one">
                    <div className="slm-lr-title-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3">Déstinataire</h3>
                            <Box style={{ "paddingRight": 15 }} className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                <TextField
                                    color={fieldState.personJob ? 'error' : 'success'}
                                    id="outlined-basic" label="Poste de la personne cible" variant="outlined" fullWidth multiline rows={1}
                                    name="personJob"
                                    className={"no-error slm-input-text"}
                                    // className={true ? "error-field slm-input-text" : "no-error slm-input-text"}
                                    value={formData.personJob}
                                    onChange={handleChangeRecipient}
                                // InputProps={{
                                //     readOnly: true,
                                // }}
                                />
                            </Box>
                        </div>
                    </div>

                    <div className="slm-lr-title-part">
                        <div className="slm-email-form-div">
                            <h3 className="slm-h3" style={{ color: "transparent" }}>Déstinataire</h3>
                            {
                                // formData && (
                                <Box style={{ "paddingRight": 15 }} className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField
                                        color={fieldState.companyName ? 'error' : 'success'}
                                        id="outlined-basic" label="Nom de l'entreprise" variant="outlined" fullWidth multiline rows={1}
                                        name="companyName"
                                        className={"no-error slm-input-text"}
                                        value={formData.companyName}
                                        onChange={handleChangeRecipient}
                                    // InputProps={{
                                    //     readOnly: true,
                                    // }}
                                    />
                                </Box>
                                // )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                runLoader ? (
                    <div className="slm-email-form-loader">
                        <LoaderResponse colorValue={COLORS.jauneClaire} w={20} h={20} />
                        <p>Envoi en cours...</p>
                    </div>
                ) : (
                    <div className="slm-email-form-footer">
                        <button type="submit" disabled={disableSendButton} style={{ cursor: disableSendButton ? "not-allowed" : "pointer" }} onClick={handleSubmit} className="slm-email-form-button">
                            Enregistrer
                        </button>
                    </div>
                )
            }
        </div>
    );
};

export default SLMTestEmailForm;
