export const criteria_key2value: Record<string, string> = {
    "captivant": "Captivant",
    "clarity": "Clarté",
    "coherence": "Cohérence",
    "emotional_impact": "Impact émotionnel",
    "fluidity": "Fluidité",
    "personalization": "Personnalisation",
    "pertinence": "Pertinence",
}

export const criteria_value2key: Record<string, string> = {
    "Captivant": "captivant",
    "Clarté": "clarity",
    "Cohérence": "coherence",
    "Impact émotionnel": "emotional_impact",
    "Fluidité": "fluidity",
    "Personnalisation": "personalization",
    "Pertinence": "pertinence",
}

export const bartKey2Text: Record<string, string> = {
    "accroche": "Accroche",
    "transition": "Transition",
    "service_value": "Proposition de valeur",
    "mail_context": "Contexte",
    "person_job": "Post occupé",
    "company_value": "Nom de l'entreprise cible",
    "company_name": "Valeur de l'entreprise cible",
    "company_domain_activity": "Activité de l'entreprise cible",
    "sender_value_proposition": "Proposition de valeur de l'émetteur"
}