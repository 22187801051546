export const USER_ID_KEY = "userID"
export const USER_INFO_KEY = "userInfoData"
export const KEY_CODE = "4zajsvnjkqsdfzea"
export const PROMPT2LLM_KEY = "PROMPT2LLM"

export const MAIL_CHECKBOX_KEY = "MAIL_CHECKBOX_DATA"
export const MAIL_LANGUAGE_KEY = "MAIL_LANGUAGE_VALUE"
export const MAIL_TITLE_CHECKBOX_KEY = "MAIL_TITLE_CHECKBOX_VALUE"
export const MAIL_ACC_CHECKBOX_KEY = "MAIL_ACC_CHECKBOX_VALUE"
export const JOB_CHECKBOX_KEY = "JOB_CHECKBOX_DATA"
export const INCLUDE_LOCALISATION_KEY = "INCLUDE_LOC_CHECKBOX_DATA"
export const EXCLUDE_LOCALISATION_KEY = "EXCLUDE_LOC_CHECKBOX_DATA"
export const TECHNOLOGY_KEY = "COMPANY_TECH"
export const DOMAINE_CHECKBOX_KEY = "DOMAIN_CHECKBOX_DATA"
export const INCITATION_KEY = "INCITATION_DATA"
export const ACCROCHE_KEY = "ACCROCHE_DATA"
export const TITRE_MAIL_KEY = "MAIL_DATA"
export const SERVICE_VALUE_KEY = "SERVICE_VALUE"

export const DEST_SAVING_STATE = "DEST_SAVING_STATUS"
export const SENDER_SAVING_STATE = "SENDER_SAVING_STATUS"
export const FOLLOWUP_SAVING_STATE = "FOLLOWUP_SAVING_STATUS"

export const RECIPIENT_KEY = "RECIPIENT_DATA"
export const SENDER_KEY = "SENDER_DATA"
export const DOC_ID_KEY = "DOC_ID_VALUE"
export const CRM_API_KEY = "CRM_KEY_VALUE"

export const SF_CREDS_DATA="SF_CREDS"
export const AP_CREDS_DATA="AP_CREDS"
export const HS_CREDS_DATA="HS_CREDS"
export const GS_CREDS_DATA="GS_CREDS"

export const V2_RECIPIENT_KEY = "RECIPIENT_DATA_V2"
export const V2_SENDER_KEY = "SENDER_DATA_V2"
export const V2_MAIL_KEY = "MAIL_DATA_V2"
export const V2_CAMPAIGN_KEY = "CAMPAIGN_DATA_V2"

export const CAMPAGN_LOAD_STATE = "CAMPAGN_LOADER_KEY"

export const FOLLOW_UP_DATA = "FOLLOW_UP"
export const HIST_DATA4EVAL = "HISTORY_DATA4EVAL"

export const BART_FORM_DATA = "formData"
export const SLM_FORM_DATA = "SLM_DATA"

export const QUESTION_POST_PUBLIE = "P_PUBLIE"
export const QUESTION_POST_REPUBLIE = "P_REPUBLIE"
export const QUESTION_POST_PARTAGE = "P_PARTAGE"