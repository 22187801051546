import * as React from 'react';
import { alpha, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Input from '@mui/material/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import SendIcon from '@mui/icons-material/Send';
import ForwardIcon from '@mui/icons-material/Forward';
import PreviewIcon from '@mui/icons-material/Preview';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import "../../styles/DataTable.css"
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { HIST_DATA4EVAL, USER_INFO_KEY } from '../../constants/StoreKey';
import { getMailStatusIfAlreadyInDPODataset, runEmailAddressRetrieval, runFollowUp, runHistoryValidateUpdate4DPO, runSendingMail } from '../../services/UserServices';
import Alert from '@mui/material/Alert';

import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { SENDER_EMAIL_ADDR } from '../../constants/textVariable';
import { encryptData } from '../../utils/util_function';
import { Redirect2NewPage } from '../../Helpers/Redirect2';

import { createTheme } from '@mui/material/styles';
import { UserCampaignDataType } from '../../type/CampaignMailType';
import { customStyle } from '../Style/ModalStyle';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    green: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    green: true;
  }
}

const theme = createTheme({
  palette: {
    black: {
      main: '#000000',
      light: '#000000',
      dark: '#000000',
      contrastText: '#000000',
    },
    green: {
      main: '#07bc0cfc',
      light: '#07bc0cfc',
      dark: '#3ee643fc',
      contrastText: '#3ee643fc',
    },
  },
});

export type ColumnNameType = {
  colname: keyof UserCampaignDataType;
  labelname: string;
}

interface ICampaignData {
  rows: Array<UserCampaignDataType>;
  columnName: Array<ColumnNameType>;
  followUpTemplate?: Array<string>;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof UserCampaignDataType;
  label: string;
  numeric: boolean;
}


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UserCampaignDataType) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof UserCampaignDataType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  let headCellsList: HeadCell[] = [{
    id: 'target_person_name',
    numeric: false,
    disablePadding: true,
    label: 'Nom du cible',
  },
  {
    id: 'target_person_job',
    numeric: true,
    disablePadding: false,
    label: 'Poste',
  },
  {
    id: 'target_person_company_name',
    numeric: true,
    disablePadding: false,
    label: 'Entreprise',
  },
  {
    id: 'mail_title',
    numeric: true,
    disablePadding: false,
    label: 'Titre',
  },
  {
    id: 'mail_body',
    numeric: true,
    disablePadding: false,
    label: 'Contenu',
  },
  {
    id: 'mail_status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'mail_state_count',
    numeric: true,
    disablePadding: false,
    label: 'Ouverture',
  },
  {
    id: 'Email',
    numeric: true,
    disablePadding: false,
    label: "Adresse email",
  },
  {
    id: 'sending_date',
    numeric: true,
    disablePadding: false,
    label: "Date d'envoi",
  },
  ]

  // columnList.map((item, index) => {
  //   headCellsList.push({
  //     id: item.colname,
  //     numeric: typeof(item) === 'number' ? true : false,
  //     disablePadding: true,
  //     label: item.labelname,
  //   })
  // })

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        <TableCell>
          <Typography
            align='left'
            sx={{ flex: '1 1 100%', fontWeight: 'bold' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            Actions
          </Typography>
        </TableCell>
        {headCellsList.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ fontWeight: 'bold' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected > 1 ? numSelected + " sélectionnés" : numSelected + " sélectionné"}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Historique globale du mail de campagne
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Supprimer">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Liste des filtres">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

// ***************************************************************************************************
// Component principale de l'historique
const HistoryCampaignTable: React.FC<ICampaignData> = ({ rows, columnName, followUpTemplate }) => {
  const hide_icon = "/images/btn/hide.png"

  const [SenderInfo, setSenderInfo] = React.useState({
    UserName: "",
    UserJob: "",
    UserCompany: ""
  })
  const [UserTarget, setUserTarget] = React.useState({
    targetPersonEmail: "",
    targetPersonName: "",
    targetCompanyName: "",
    targetPersonJob: ""
  })
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof UserCampaignDataType>('sending_date');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedId, setSelectedId] = React.useState(-1);
  const [mailSent, setMailSent] = React.useState(false)

  const [modal2show, setModal2show] = React.useState<"resend" | "detail" | "followup" | "none">("none");
  const [openResendMail, setOpenResendMail] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openFollowUp, setOpenFollowUp] = React.useState(false);

  const [ButtonLoader, setButtonLoader] = React.useState(false)
  const [resend_data2send, setResend_data2send] = React.useState({
    recipient: "",
    sender: SENDER_EMAIL_ADDR,
    subject: "",
    body: "",
    sender_name: SenderInfo.UserName,
    sender_job: SenderInfo.UserJob,
    sender_company_name: SenderInfo.UserCompany,
    mail_id: ""
  })

  const [followup_data2send, setFollowup_data2send] = React.useState({
    recipient: "",
    sender: SENDER_EMAIL_ADDR,
    subject: "",
    body: "",
    sender_name: SenderInfo.UserName,
    sender_job: SenderInfo.UserJob,
    sender_company_name: SenderInfo.UserName,
    mail_id: ""
  })

  const [campaignHistoryData, setCampaignHistoryData] = React.useState({
    title: "",
    body: ""
  })

  const handleOpenResendMail = (id: number) => {
    setMailSent(false)
    setModal2show("resend")
    setOpenResendMail(true)
    setSelectedId(id)
    setResend_data2send({
      recipient: rows[id].Email,
      sender: SENDER_EMAIL_ADDR,
      subject: rows[id].mail_title,
      body: rows[id].mail_body,
      sender_name: SenderInfo.UserName,
      sender_job: SenderInfo.UserJob,
      sender_company_name: SenderInfo.UserCompany,
      mail_id: rows[id].mail_id
    })

    setUserTarget({
      targetPersonEmail: rows[id].Email,
      targetPersonName: rows[id].target_person_name,
      targetCompanyName: rows[id].target_person_company_name,
      targetPersonJob: rows[id].target_person_job,
    })
  };

  /**Action après l'ouverture du modal d'aperçu des données */
  const handleOpenInfo = (id: number) => {
    setModal2show("detail")
    setOpenInfo(true)
    setSelectedId(id)

    const data = rows[id]
    setResend_data2send({
      recipient: data.Email,
      sender: SENDER_EMAIL_ADDR,
      subject: data.history_title ? data.history_title : data.mail_title,
      body: data.history_body ? data.history_body : data.mail_body,
      sender_name: SenderInfo.UserName,
      sender_job: SenderInfo.UserJob,
      sender_company_name: SenderInfo.UserCompany,
      mail_id: data.mail_id
    })
  };

  /**Action après l'ouverture du modal de relance */
  const handleOpenFollowUp = (id: number) => {
    setMailSent(false)
    setModal2show("followup")
    setOpenFollowUp(true)
    setSelectedId(id)
    setFollowup_data2send({
      recipient: rows[id].Email,
      sender: SENDER_EMAIL_ADDR,
      subject: rows[id].mail_title,
      body: followUpTemplateGen(UserTarget.targetPersonName, UserTarget.targetCompanyName),
      sender_name: SenderInfo.UserName,
      sender_job: SenderInfo.UserJob,
      sender_company_name: SenderInfo.UserCompany,
      mail_id: rows[id].mail_id
    })

    setUserTarget({
      targetPersonEmail: rows[id].Email,
      targetPersonName: rows[id].target_person_name,
      targetCompanyName: rows[id].target_person_company_name,
      targetPersonJob: rows[id].target_person_job,
    })
  };

  const prepareDataForFeedback = (idx: number, btn_state: number) => {
    // Bloquer le click du bouton d'évaluation lorsque le mail a été déjà évalué 
    if(btn_state === 1) return

    const dataById = rows[idx]
    
    const data2store4evaluation = {
      companyName: dataById.target_person_company_name,
      correctedMail: "",
      correctedMailTitle: "",
      generatedMail: dataById.mail_body,
      generatedMailTitle: dataById.mail_title,
      mailContext: dataById.mail_context_summary,
      mailId: dataById.mail_id,
      personJob: dataById.target_person_job,
      postCategory : dataById.mail_context_category,
      senderValueProposition: "",
      senderName: SenderInfo.UserName,
      senderJob: SenderInfo.UserJob,
      senderCompanyName: SenderInfo.UserCompany,
      targetCompanyValue: "",
      targetCompanyActivity: "",
    }
    
    encryptData(HIST_DATA4EVAL, data2store4evaluation)
    // Redirection vers la page d'évaluation du mail pour créer le dataset DPO
    Redirect2NewPage("slm-evaluation", "", dataById.mail_id)
  }

  const retrieveEmailAddress = (id: number) => {
    setSelectedId(id)

    const email_request_data = {
      userid: rows[id].user_id,
      job: rows[id].target_person_job,
      name: rows[id].target_person_name,
      mail_id: rows[id].mail_id,
      company: rows[id].target_person_company_name,
    }

    runEmailAddressRetrieval(email_request_data).then((rep) => {
      const status_res = rep.body
      if (status_res) {
        window.location.reload();
      }
    }).catch((error) => console.log(error))
  };

  const handleClose = () => {
    setOpenInfo(false);
    setOpenFollowUp(false)
    setSelectedId(-1)
    setModal2show("none")
  }

  const random_number = (max_num = 2) => {
    return Math.floor(Math.random() * max_num);
  }

  const followUpTemplateGen = (name: string, company: string) => {
    return followUpTemplate ? followUpTemplate[random_number(followUpTemplate?.length)].replace("{name}", name).replace("{company}", company) : ""
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserCampaignDataType,
  ) => {

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const modifyDisplayingDate = (totalSeconds: string) => { // Exemple de nombre total de secondes

    // Convertir les secondes en millisecondes
    const milliseconds = parseInt(totalSeconds, 10) * 1000;

    // Créer un objet Date à partir des millisecondes
    const dateObj = new Date(milliseconds);

    // Extraire et formater la date
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Les mois sont basés sur 0
    const year = dateObj.getUTCFullYear();
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

    // Affichage sous le format "09/07/2024 à 14h31"
    const formattedDate = `${day}/${month}/${year} à ${hours}h${minutes}`;

    return formattedDate
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const validateAndReSendMail = () => {
    if (!resend_data2send.recipient) {
      return
    }
    if (!resend_data2send.subject) {
      return
    }
    if (!resend_data2send.body) {
      return
    }
    // if (!resend_data2send.sender_job) {
    //   return
    // }
    // if (!resend_data2send.sender_company_name) {
    //   return
    // }

    // console.log("resend2 : ", resend_data2send)

    setButtonLoader(true)
    runSendingMail(resend_data2send).then((response) => {
      setButtonLoader(false)
      handleClose()
      setMailSent(true)
    }).catch((e) => {
      console.log(e)
      setButtonLoader(false)
    })
  }

  const validateAndUpdateCampagn = (data: UserCampaignDataType, sender_mail_address: string = SENDER_EMAIL_ADDR) => {
    const campagn_data2update = {
      userid: data.user_id,
      mail_id: data.mail_id,
      recipient: data.Email,
      sender: sender_mail_address,
      subject: data.mail_title,
      body: data.mail_body,
      sender_name: SenderInfo.UserName,
      sender_job: SenderInfo.UserJob,
      sender_company_name: SenderInfo.UserCompany,
      history: {
        title: campaignHistoryData.title ? campaignHistoryData.title : data.mail_title,
        body: campaignHistoryData.body ? campaignHistoryData.body : data.mail_body,
        version: data.history_version,
        extraction: JSON.parse(data.history_extraction)
      }
    }

    setButtonLoader(true)
    runHistoryValidateUpdate4DPO(campagn_data2update).then((response) => {
      setButtonLoader(false)
      handleClose()
      window.location.reload()
    }).catch((e) => {
      console.log(e)
      setButtonLoader(false)
    }
    )

  }

  const validateAndSendFollowUp = () => {
    console.log(followup_data2send)
    if (!followup_data2send.recipient) {
      return
    }
    if (!followup_data2send.subject) {
      return
    }
    if (!followup_data2send.body) {
      return
    }
    if (!followup_data2send.sender_company_name) {
      return
    }
    if (!followup_data2send.sender_job) {
      return
    }
    console.log(followup_data2send)

    // setButtonLoader(true)
    // runFollowUp(followup_data2send).then((response) => {
    //   setButtonLoader(false)
    //   handleClose()
    //   setMailSent(true)
    // }).catch((e) => {
    //     console.log(e)
    //     setButtonLoader(false)
    //   }
    // )
  }

  const displayMailView = () => {
    return (
      <Modal
        keepMounted
        open={openInfo}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={customStyle}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h4">
            Informations par rapport à la campagne pour {rows[selectedId].target_person_company_name}
          </Typography>
          <div>
            <div style={{ width: '100%', height: 1, background: 'black', marginBottom: 25 }}></div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="outlined-basic"
                  label="Adresse email destinataire"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={rows[selectedId].Email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setResend_data2send(prev => ({
                      ...prev,
                      recipient: event.target.value
                    }))
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Nom de la personne cible"
                  variant="outlined"
                  fullWidth
                  multiline
                  disabled
                  rows={1}
                  defaultValue={rows[selectedId].target_person_name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Poste de la cible"
                  variant="outlined"
                  fullWidth
                  multiline
                  disabled
                  rows={1}
                  defaultValue={rows[selectedId].target_person_job}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    // setName(event.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Entreprise de la cible"
                  variant="outlined"
                  fullWidth
                  multiline
                  disabled
                  rows={1}
                  defaultValue={rows[selectedId].target_person_company_name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    // setName(event.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Titre du mail"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={rows[selectedId].history_title ? rows[selectedId].history_title : rows[selectedId].mail_title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCampaignHistoryData(prev => ({
                      ...prev,
                      title: event.target.value
                    }));
                  }}
                />

                <TextField
                  id="outlined-multiline-static"
                  label="Corps du mail"
                  fullWidth
                  multiline
                  rows={10}
                  defaultValue={rows[selectedId].history_body ? rows[selectedId].history_body : rows[selectedId].mail_body}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCampaignHistoryData(prev => ({
                      ...prev,
                      body: event.target.value
                    }));
                  }}
                />
              </div>
              <Box
                sx={{
                  display: 'flex', justifyContent: 'center'
                }}
              >
                <ButtonGroup variant="outlined" disabled={ButtonLoader}>
                  <Button color='error' variant="outlined" onClick={handleClose}>Quitter</Button>
                  <Button variant="outlined" onClick={() => validateAndUpdateCampagn(rows[selectedId])}>Modifier</Button>
                  {
                    rows[selectedId].history_title && rows[selectedId].history_body &&
                    <Tooltip title="Envoyer le mail validé vers la cible">
                      <Button color='success' variant="outlined" onClick={validateAndReSendMail}>Envoyer</Button>
                    </Tooltip>
                  }
                </ButtonGroup>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    )
  }

  const displayFollowUpView = () => {
    const ariaLabel = { 'aria-label': 'description' };

    return (
      <Modal
        keepMounted
        open={openFollowUp}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={customStyle}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h4">
            Relance pour {UserTarget.targetPersonName} chez {UserTarget.targetCompanyName}
          </Typography>
          <div>
            <div style={{ width: '100%', height: 1, background: 'black', marginBottom: 25 }}></div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >

              <div>
                <TextField
                  id="outlined-basic"
                  label="Adresse email destinataire"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={followup_data2send.recipient}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserTarget(prevS => ({ ...prevS, targetPersonEmail: event.target.value }));
                    setFollowup_data2send(prevS => ({ ...prevS, recipient: event.target.value }));
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Titre du mail"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={followup_data2send.subject}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFollowup_data2send(
                      prevState => ({ ...prevState, subject: event.target.value })
                    )
                  }}
                />

                <TextField
                  id="outlined-multiline-static"
                  label="Corps du mail"
                  fullWidth
                  multiline
                  rows={10}
                  defaultValue={followup_data2send.body}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFollowup_data2send(
                      prevState => ({ ...prevState, body: event.target.value })
                    )
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Poste de l'émetteur"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={followup_data2send.sender_job}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSenderInfo(prevS => ({ ...prevS, UserJob: event.target.value }));
                    setFollowup_data2send(prevS => ({ ...prevS, sender_job: event.target.value }));
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Entreprise émetteur"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={followup_data2send.sender_company_name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSenderInfo(prevS => ({ ...prevS, UserCompany: event.target.value }));
                    setFollowup_data2send(prevS => ({ ...prevS, sender_company_name: event.target.value }));
                  }}
                />
              </div>
              <Box
                sx={{
                  display: 'flex', justifyContent: 'center'
                }}
              >
                <ButtonGroup variant="outlined">
                  <Button color='error' variant="outlined" disabled={ButtonLoader} onClick={handleClose}>Annuler</Button>
                  {
                    ButtonLoader ? (
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Envoi
                      </LoadingButton>
                    ) : (
                      <Button variant="outlined" onClick={validateAndSendFollowUp}>Modifier</Button>
                    )
                  }

                  {
                    rows[selectedId].history_title && rows[selectedId].history_body &&
                    <Tooltip title="Envoyer le mail de relance vers la cible">
                      {/* <Button color='success' variant="outlined" onClick={validateAndReSendMail}>Envoyer</Button> */}
                      <Button color='success' variant="outlined" onClick={validateAndSendFollowUp}>Envoyer</Button>
                    </Tooltip>
                  }
                </ButtonGroup>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    )
  }

  const displayResendMailView = () => {

    return (
      <Modal
        keepMounted
        open={openResendMail}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={customStyle}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h4">
            Mail généré pour {UserTarget.targetPersonName} chez {UserTarget.targetCompanyName}
          </Typography>
          <div>
            <div style={{ width: '100%', height: 1, background: 'black', marginBottom: 25 }}></div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >

              <div>
                <TextField
                  id="outlined-basic"
                  label="Adresse email destinataire"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={resend_data2send.recipient}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserTarget(prevS => ({ ...prevS, targetPersonEmail: event.target.value }));
                    setResend_data2send(prevS => ({ ...prevS, recipient: event.target.value }));
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Titre du mail"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={resend_data2send.subject}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setResend_data2send(prevS => ({ ...prevS, subject: event.target.value }));
                  }}
                />

                <TextField
                  id="outlined-multiline-static"
                  label="Corps du mail"
                  fullWidth
                  multiline
                  rows={10}
                  defaultValue={resend_data2send.body}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setResend_data2send(prevS => ({ ...prevS, body: event.target.value }));
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Poste de l'émetteur"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={resend_data2send.sender_job}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSenderInfo(prevS => ({ ...prevS, UserJob: event.target.value }));
                    setResend_data2send(prevS => ({ ...prevS, sender_job: event.target.value }));
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Entreprise émetteur"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={1}
                  defaultValue={resend_data2send.sender_company_name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSenderInfo(prevS => ({ ...prevS, UserCompany: event.target.value }));
                    setResend_data2send(prevS => ({ ...prevS, sender_company_name: event.target.value }));
                  }}
                />
              </div>
              <Box
                sx={{
                  display: 'flex', justifyContent: 'center'
                }}
              >
                <ButtonGroup variant="outlined">
                  <Button color='error' variant="outlined" disabled={ButtonLoader} onClick={handleClose}>Annuler</Button>
                  {
                    ButtonLoader ? (
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Envoi
                      </LoadingButton>
                    ) : (
                      <Button variant="outlined" onClick={validateAndReSendMail}>Renvoyer</Button>
                    )
                  }
                </ButtonGroup>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    )
  }

  const modalViewer = () => {
    switch (modal2show) {
      case "resend":
        return displayResendMailView()
      case "detail":
        return displayMailView()
      case "followup":
        return displayFollowUpView()
      default:
        return <></>
    }
  }

  React.useEffect(() => {
    const ls_storage_infoData = localStorage.getItem(USER_INFO_KEY)

    if (ls_storage_infoData) {
      const json_infoData = JSON.parse(ls_storage_infoData)

      setSenderInfo(prevS => ({ ...prevS, UserName: json_infoData['firstName'] + " " + json_infoData['lastName'] }))
    }

    // Vérifier dans la collection du dpo si le mail a été déjà évalué
    // if(rows[id].mail_id) {
    //   getMailStatusIfAlreadyInDPODataset(rows[id].mail_id).then(
    //     (rep) => {
    //       if(rep.body) {

    //       }
    //     }
    //   ).catch((e) => console.error(e))
    // }
  }, [])


  return (
    <>
      {
        mailSent && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Mail envoyé
        </Alert>
      }
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={index}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Tooltip title="Sélectionner tout">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </Tooltip>
                        </TableCell>

                        <TableCell>
                          <ButtonGroup variant="outlined">
                            {/* <Tooltip title="Renvoyer">
                            <Button 
                              color="success"
                              size="small" 
                              onClick={() => handleOpenResendMail(row.id)}
                              >
                              <SendIcon />
                            </Button>
                          </Tooltip> */}

                            <Tooltip title="Aperçu">
                              <Button
                                color="error"
                                size="small"
                                onClick={() => handleOpenInfo(row.id)}
                              >
                                <PreviewIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip title="Relance">
                              <Button
                                size="small"
                                onClick={() => handleOpenFollowUp(row.id)}
                              >
                                <ForwardIcon />
                              </Button>
                            </Tooltip>

                            {
                              (row.user_id === "6603de4d09f9d7cf704e3bf3" || row.user_id === "6602f90e257b0c02ef0ab503") && (
                                <Tooltip title={row.evaluation_state < 0 ? 'Red flag' : row.evaluation_state === 0 ? 'Non évalué' : 'Déjà évalué'}>
                                  <Button
                                    color={row.evaluation_state < 0 ? 'error' : row.evaluation_state === 0 ? 'black' : 'green'}
                                    style={{ cursor: row.evaluation_state === 0 ? "pointer" : "not-allowed"}}
                                    size="small"
                                    onClick={() => prepareDataForFeedback(row.id, row.evaluation_state)}
                                  >
                                    <ThumbUpOffAltIcon />
                                  </Button>
                                </Tooltip>
                              )
                            }
                          </ButtonGroup>
                        </TableCell>
                        {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.mail_id}
                      </TableCell> */}
                        <TableCell align="right"><Typography align='left'>{row.target_person_name}</Typography></TableCell>
                        <TableCell align="right"><Typography align='left'>{row.target_person_job}</Typography></TableCell>
                        <TableCell align="right"><Typography align='left'>{row.target_person_company_name}</Typography></TableCell>
                        <TableCell align="right">
                          <p className='mail-body-displaying'>{row.mail_title}</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className='mail-body-displaying'>{row.mail_body}</p>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title={row.mail_status !== "NON LU" ? "Ouvert & lu" : "Non ouvert"}>
                            {
                              row.mail_status !== "NON LU" ? <MarkEmailReadIcon color="success" /> : <MarkEmailUnreadIcon />
                            }
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title={row.mail_state_count > 0 ? `${row.mail_state_count} fois ouvert` : 'Pas encore ouvert'}>
                            <Typography align='center' variant='h5'>{row.mail_state_count}</Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          <Typography align='left' color="primary">
                            {
                              row.Email ? row.Email : (
                                <Button
                                  color="success"
                                  size="small"
                                  onClick={() => retrieveEmailAddress(row.id)}
                                >
                                  <img src={hide_icon} alt="" title={"Caché"} width={30} height={30} style={{ cursor: "pointer" }} />
                                </Button>
                              )
                            }
                          </Typography>
                        </TableCell>
                        <TableCell align="right"><Typography align='left'>{modifyDisplayingDate(row.sending_date)}</Typography></TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Ligne par page"
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
        </Box>
      </ThemeProvider>
      {
        rows.length && (
          modalViewer()
        )
      }
    </>
  );
}

export default HistoryCampaignTable