export const accrocheDefaultData: any = {
    "accroche": {
        "pertinence": [
            {
                "question": "",
                "key": []
            }
        ],
        "clarity": [
            {
                "question": "",
                "key": []
            }
        ],
        "captivant": [
            {
                "question": "",
                "key": []
            }
        ],
        "personalization": [
            {
                "question": "",
                "key": []
            }
        ]
    }
}

export const accrocheDefaultData2: any = {
    "accroche": {
        "pertinence": [
            {
                "question": "L’accroche mentionne-t-elle le post que la personne a publié sur LinkedIn ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche mentionne-t-elle un sujet abordé dans le post du destinataire ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche mentionne-t-elle un événement ou une activité précisée dans le post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche reprend-elle des mots-clés du post, tels que {Context_keyword} ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche mentionne-t-elle un produit ou une solution spécifique ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche reformule-t-elle l’idée principale du post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche mentionne-t-elle un événement auquel {name} a assisté ou auquel il/elle participera ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche fait-elle allusion à une priorité pour {name} ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche utilise-t-elle des informations tirées du post LinkedIn ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche respecte-t-elle le sujet principal du post LinkedIn ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche met-elle en avant un défi, une opportunité ou un succès mentionné dans le post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche inclut-elle un élément en lien avec son poste ou son entreprise ?",
                "key": ["accroche", "person_job", "mail_context"]
            },
            {
                "question": "L'accroche met-elle en avant un aspect positif ou un succès présenté dans le post de {name} ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche contient-elle un acronyme présent dans le post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche mentionne-t-elle {company} ?",
                "key": ["accroche", "company_name"]
            },
            {
                "question": "L’accroche utilise-t-elle les termes liés à {company_domain_activity} ?",
                "key": ["accroche", "company_domain_activity"]
            },
            {
                "question": "Le domaine d’activité est-il utilisé pour établir une connexion avec le destinataire ?",
                "key": ["accroche", "company_domain_activity"]
            },
            {
                "question": "L’accroche évoque-t-elle des innovations pertinentes pour le secteur {company_domain_activity} ?",
                "key": ["accroche", "company_domain_activity"]
            },
            {
                "question": "L'accroche traite-t-elle de l’utilisation de l’IA dans le domaine de {company_domain_activity} ?",
                "key": ["accroche", "company_domain_activity"]
            },
            {
                "question": "L’accroche fait-elle référence à un projet particulier de {name} ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L’accroche semble-t-elle adaptée pour un {person_job} ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L'accroche évoque-t-elle des objectifs pour un {person_job} (tels que Croissance, Performance, Stratégie, Innovation, Résultats) ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L'accroche mentionne-t-elle des défis liés au rôle de {person_job} (tels que objectifs, motivation, marché, compétences et innovation) ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L'accroche reflète-t-elle les responsabilités de {name} en tant que {person_job} ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L’accroche donne-t-elle une raison claire pour que {name} se sente personnellement concerné(e) ?",
                "key": ["accroche", "person_job", "mail_context"]
            }
        ],
        "clarity": [
            {
                "question": "L’accroche fait-elle moins de 35 mots ?",
                "key": ["accroche"]
            },
            {
                "question": "Le vocabulaire utilisé est-il compréhensible par le destinataire sans ambiguïté ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche évite-t-elle les termes trop techniques ?",
                "key": ["accroche"]
            },
            {
                "question": "La phrase est-elle facile à comprendre ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche utilise-t-elle des mots simples et directs ?",
                "key": ["accroche"]
            },
            {
                "question": "Les phrases de l’accroche s’enchaînent-elles logiquement ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche suit-elle une progression logique (sujet-verbe-complément) ?",
                "key": ["accroche"]
            },
            {
                "question": "Aucune contradiction n’est-elle présente dans les phrases de l’accroche ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche évite-t-elle des idées vagues ou des affirmations floues ?",
                "key": ["accroche"]
            },
            {
                "question": "Les phrases restent-elles centrées sur le destinataire ?",
                "key": ["accroche", "person_job", "mail_context"]
            },
            {
                "question": "L’accroche va-t-elle droit au but ?",
                "key": ["accroche"]
            },
            {
                "question": "Les éléments mentionnés dans l’accroche sont-ils précis ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche cite-t-elle des termes spécifiques pour un {person_job} ou au {company_domain_activity} ?",
                "key": ["accroche", "mail_context", "company_domain_activity", "person_job"]
            },
            {
                "question": "Aucune phrase de l’accroche n’est-elle abstraite ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche contient-elle uniquement les informations essentielles ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche commence-t-elle par une phrase claire ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche suit-elle une structure logique et fluide ?",
                "key": ["accroche"]
            },
            {
                "question": "Les idées principales sont-elles présentées dès le début de l’accroche ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Les mots-clés comme {Context_keyword} ou éléments importants apparaissent-ils au début de chaque phrase ?",
                "key": ["accroche", "mail_context", "company_value"]
            },
            {
                "question": "Aucune partie de l’accroche n’est-elle répétitive ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche évite-t-elle les acronymes ou abréviations non expliquées ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche évite-t-elle les répétitions inutiles ?",
                "key": ["accroche"]
            },
            {
                "question": "La construction grammaticale est-elle correcte et fluide ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche présente-t-elle les informations de manière concise et sans surcharge ?",
                "key": ["accroche"]
            },
            {
                "question": "Les mots utilisés sont-ils précis et évitent-ils les généralités ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche reste-t-elle claire tout en étant personnalisée ?",
                "key": ["accroche", "mail_context", "person_job"]
            }
        ],
        "captivant": [
            {
                "question": "L’accroche suscite-t-elle la curiosité en faisant un lien avec un sujet du destinataire ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche donne-t-elle envie d’en savoir plus sur le sujet abordé ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche donne-t-elle l'impression qu'il y a quelque chose d'important à découvrir ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche donne-t-elle l’impression d’un sujet inédit ou novateur ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche met-elle en avant un défi ou un problème à résoudre ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche mentionne-t-elle un changement ou une nouveauté ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche commence-t-elle avec une phrase qui intrigue ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche mentionne-t-elle un événement pertinent par rapport à la proposition de valeur ?",
                "key": ["accroche", "mail_context", "sender_value_proposition"]
            },
            {
                "question": "L’accroche fait-elle un lien explicite avec le contexte du destinataire ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche reflète-t-elle une compréhension des défis du destinataire ?",
                "key": ["accroche", "person_job"]
            },
            {
                "question": "L'accroche donne-t-elle l'impression d'un message personnalisé pour le destinataire ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L'accroche contient-elle des éléments spécifiques au destinataire qui la différencient d’un message générique ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L'accroche démontre-t-elle un intérêt pour le destinataire ?",
                "key": ["accroche", "person_job", "mail_context"]
            },
            {
                "question": "L'accroche donne-t-elle l'impression que l'émetteur connaît bien le contexte du destinataire ?",
                "key": ["accroche", "person_job", "mail_context"]
            },
            {
                "question": "L'accroche fait-elle sentir au destinataire qu'il est important pour l'émetteur ?",
                "key": ["accroche", "person_job", "mail_context"]
            },
            {
                "question": "L’accroche adopte-t-elle une approche différente des standards des emails commerciaux ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche utilise-t-elle un ton, un style ou une structure inhabituels ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche évite-t-elle des phrases trop commerciales ?",
                "key": ["accroche"]
            },
            {
                "question": "L'accroche utilise-t-elle une métaphore ou une image frappante pour se différencier ?",
                "key": ["accroche"]
            },
            {
                "question": "L’accroche contient-elle des mots émotionnels forts ?",
                "key": ["accroche"]
            },
            {
                "question": "L'accroche montre-t-elle une attention particulière aux besoins ou aux intérêts du destinataire ?",
                "key": ["accroche", "person_job"]
            },
            {
                "question": "L'accroche reflète-t-elle une compréhension approfondie du secteur d’activité du destinataire ?",
                "key": ["accroche", "company_domain_activity"]
            },
            {
                "question": "L'accroche montre-t-elle que l'émetteur se soucie des objectifs du destinataire ?",
                "key": ["accroche", "person_job", "mail_context"]
            },
            {
                "question": "L'accroche propose-t-elle quelque chose de concret, sans être trop vague ou générique ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche fait-elle sentir au destinataire qu'il/elle est au centre de l'attention ?",
                "key": ["accroche", "person_job", "mail_context"]
            }
        ],
        "personalization": [
            {
                "question": "La publication LinkedIn de la personne est-elle mentionnée explicitement dans l'accroche ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Une phrase spécifique liée au contenu de la publication est-elle utilisée dans l'accroche ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche reflète-t-elle les idées principales du post que la personne a publié ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche est-elle en lien avec une compétence ou une expertise spécifique du destinataire ?",
                "key": ["accroche", "mail_context", "person_job"]
            },
            {
                "question": "L'accroche mentionne-t-elle un élément qui montre que vous avez lu le contenu en détail ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Y a-t-il une question ou une réflexion en lien direct avec la publication ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Le sujet de la publication est-il lié à la proposition de valeur de l’email ?",
                "key": ["accroche", "mail_context", "sender_value_proposition"]
            },
            {
                "question": "Une expression ou un style spécifique au post est-il repris ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche montre-t-elle un alignement avec l’opinion de la personne dans sa publication ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Une date ou un contexte spécifique lié à la publication est-il mentionné ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche reflète-t-elle un lien entre le post et la proposition de valeur ?",
                "key": ["accroche", "mail_context", "sender_value_proposition"]
            },
            {
                "question": "La publication est-elle utilisée pour ouvrir un dialogue avec la personne ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "La terminologie ou les mots-clés utilisés dans le post apparaissent-ils dans l'accroche ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche évoque-t-elle une idée nouvelle inspirée de la publication ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche fait-elle preuve d’un effort manifeste pour relier le post au message global ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche contient-elle des références spécifiques au profil ou au post du destinataire ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche reprend-elle un mot-clé important du post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche évoque-t-elle une problématique ou une opportunité soulevée dans le post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche intègre-t-elle des éléments tirés du post publié par le destinataire ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Le message fait-il ressentir que l’accroche a été rédigée spécifiquement pour cette personne ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche mentionne-t-elle un objectif ou une intention exprimée dans la publication ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Le timing du post est-il mentionné ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L’accroche contient-elle une réponse explicite au post ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "Une référence à un événement ou une actualité mentionnée dans le post est-elle intégrée ?",
                "key": ["accroche", "mail_context"]
            },
            {
                "question": "L'accroche évite-t-elle les généralités pour s'appuyer uniquement sur les détails du post ?",
                "key": ["accroche", "mail_context"]
            }
        ]
    }
}