export const defaultSegmentData: any = {
    "transition":
    {
        "coherence": [
            {
                "question": "La phrase de transition fait-elle le lien naturel entre l'accroche et la proposition de valeur ?",
                "key": [
                    "transition",
                    "sender_value_proposition",
                    "accroche"
                ]
            },
            {
                "question": "La transition relie-t-elle bien les enjeux mentionnés dans l'accroche ?",
                "key": [
                    "transition",
                    "sender_value_proposition",
                    "accroche"
                ]
            },
            {
                "question": "La transition fait-elle en sorte que le passage à la proposition de valeur se fasse sans rupture ?",
                "key": [
                    "transition",
                    "accroche",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition guide-t-elle le lecteur de manière fluide vers la proposition de valeur ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La proposition de valeur semble-t-elle être une réponse à ce qui a été évoqué dans l'accroche ?",
                "key": [
                    "transition",
                    "sender_value_proposition",
                    "accroche"
                ]
            },
            {
                "question": "La solution proposée semble-t-elle découler logiquement de la situation introduite dans l'accroche ?",
                "key": [
                    "transition",
                    "accroche",
                    "mail_context"
                ]
            },
            {
                "question": "La transition ne modifie-t-elle pas abruptement le style de l'accroche ?",
                "key": [
                    "transition",
                    "accroche"
                ]
            },
            {
                "question": "La transition crée-t-elle une continuité dans l’atmosphère du message ?",
                "key": [
                    "transition",
                    "sender_value_proposition",
                    "accroche"
                ]
            },
            {
                "question": "La proposition de valeur s'inscrit-elle de façon naturelle après l'accroche ?",
                "key": [
                    "transition",
                    "accroche",
                    "mail_context"
                ]
            },
            {
                "question": "Les questions sont-elles pertinentes et alignées avec les préoccupations du destinataire ?",
                "key": [
                    "transition",
                    "person_job",
                    "mail_context"
                ]
            },
            {
                "question": "Les questions encouragent-elles une réponse implicite favorable à la proposition de valeur ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "Aucune information ou suggestion dans la transition ne contredit-elle les points évoqués dans l’accroche ou la proposition de valeur ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition guide-t-elle naturellement le lecteur vers la proposition de valeur, en restant alignée avec l’objectif de l’email ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition prépare-t-elle efficacement le terrain pour la proposition de valeur, sans détourner le lecteur de l’objectif principal ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition ne semble-t-elle pas forcée ou artificielle dans le contexte du mail ?",
                "key": [
                    "transition",
                    "accroche",
                    "mail_context"
                ]
            }
        ],
        "clarity": [
            {
                "question": "La phrase de transition est-elle claire et directe ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "La phrase de transition ne contient-elle pas de termes ambigus ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "Le destinataire peut-il rapidement comprendre la relation entre l'accroche et la proposition de valeur ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La phrase de transition élimine-t-elle toute confusion quant à l'intention de la proposition ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition utilise-t-elle un langage simple et compréhensible ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "Les mots de la transition sont-ils choisis de manière à éviter toute complexité inutile ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "La transition ne surcharge-t-elle pas le message avec des informations ou des détails superflus ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "La phrase de transition reste-t-elle concise tout en maintenant sa clarté ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "La transition améliore-t-elle la compréhension générale du mail ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition contribue-t-elle à une meilleure compréhension du lien entre l’accroche et la proposition de valeur ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            }
        ],
        "fluidity": [
            {
                "question": "La transition permet-elle une lecture fluide de l’email ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La phrase de transition facilite-t-elle la progression naturelle du mail ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition fait-elle en sorte que la lecture soit agréable ?",
                "key": [
                    "transition"
                ]
            },
            {
                "question": "La transition facilite-t-elle un passage naturel d'une idée à l'autre ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition permet-elle un enchaînement naturel entre l'accroche et la proposition de valeur ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La phrase de transition assure-t-elle une continuité logique entre le problème et la solution ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition donne-t-elle l'impression d'une progression fluide dans le message ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "Le texte ne semble-t-il pas brusquement coupé ou trop soudain après la transition ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition permet-elle de lier deux idées de manière cohérente et fluide ?",
                "key": [
                    "accroche",
                    "mail_context",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition permet-elle de maintenir une harmonie tonale entre les différentes parties du mail ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "Les questions jouent-elles le rôle de pont naturel entre l’accroche et la solution proposée ?",
                "key": [
                    "accroche",
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition inclut-elle des questions ouvertes pour capter l’attention du lecteur ?",
                "key": [
                    "accroche",
                    "transition"
                ]
            },
            {
                "question": "L’utilisation du pronom « je » ou « nous » favorise-t-elle une connexion humaine et personnalisée ?",
                "key": [
                    "accroche",
                    "mail_context",
                    "transition"
                ]
            },
            {
                "question": "La transition introduit-elle des questions engageantes qui stimulent la réflexion ou suscitent l’intérêt du destinataire ?",
                "key": [
                    "transition",
                    "sender_value_proposition"
                ]
            },
            {
                "question": "La transition évite-t-elle un effet de 'copier-coller' en étant bien intégrée au texte ?",
                "key": [
                    "accroche",
                    "mail_context",
                    "transition"
                ]
            }
        ]
    },
    "service_value": {
        "pertinence": [
            {
                "question": "La proposition de valeur fait-elle écho à un sujet mentionné dans le post ?",
                "key": ["service_value", "person_job", "mail_context"]
            },
            {
                "question": "La proposition fait-elle un lien explicite entre une publication LinkedIn récente et la solution proposée ?",
                "key": ["accroche", "mail_context", "service_value"]
            },
            {
                "question": "La proposition de valeur répond-elle à une problématique soulevée par {name} dans son post LinkedIn ?",
                "key": ["mail_context", "service_value"]
            },
            {
                "question": "La solution proposée est-elle en accord avec les idées exprimées par {name} ?",
                "key": ["mail_context", "service_value"]
            },
            {
                "question": "La proposition de valeur met-elle en avant un avantage distinctif ?",
                "key": ["service_value"]
            },
            {
                "question": "Met-elle en avant un atout distinctif que le destinataire ne trouverait nulle part ailleurs ?",
                "key": ["service_value"]
            },
            {
                "question": "Mentionne-t-elle un résultat concret ?",
                "key": ["service_value"]
            },
            {
                "question": "Permet-elle au destinataire d’anticiper un retour sur investissement clair ?",
                "key": ["service_value"]
            },
            {
                "question": "Inspire-t-elle confiance quant à l’efficacité de la solution proposée ?",
                "key": ["service_value"]
            },
            {
                "question": "La proposition met-elle en avant un bénéfice suffisamment important pour justifier un RDV ?",
                "key": ["service_value"]
            },
            {
                "question": "Le message évite-t-il les phrases génériques (comme : une solution innovante) sans explication ?",
                "key": ["service_value"]
            },
            {
                "question": "Met-il en avant un bénéfice quantifiable et mesurable ?",
                "key": ["service_value"]
            },
            {
                "question": "L’email montre-t-il pourquoi il est plus intéressant d’accepter un RDV avec cet émetteur plutôt qu’un autre ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "La proposition de valeur est-elle adaptée aux enjeux spécifiques du secteur {company_domain_activity} ?",
                "key": ["service_value", "company_domain_activity"]
            },
            {
                "question": "Les besoins typiques du secteur sont-ils pris en compte dans la solution proposée ?",
                "key": ["service_value", "company_domain_activity"]
            },
            {
                "question": "La proposition de valeur mentionne-t-elle une solution adaptée aux opportunités du secteur {company_domain_activity} ?",
                "key": ["service_value", "company_domain_activity"]
            },
            {
                "question": "L’email évite-t-il des généralités qui ne s’appliquent pas au domaine d’activité du destinataire ?",
                "key": ["service_value", "company_domain_activity"]
            },
            {
                "question": "La solution proposée témoigne-t-elle d’une bonne connaissance des pratiques et standards du secteur {company_domain_activity} ?",
                "key": ["service_value", "company_domain_activity"]
            },
            {
                "question": "La proposition de valeur évite-t-elle les généralités ou les affirmations vagues ?",
                "key": ["service_value"]
            },
            {
                "question": "La proposition de valeur donne-t-elle une raison forte de prendre un RDV sans attendre ?",
                "key": ["service_value"]
            },
            {
                "question": "L’email montre-t-il une compréhension des objectifs professionnels typiques liés au poste de {person_job} ?",
                "key": ["service_value", "person_job"]
            },
            {
                "question": "Les bénéfices présentés correspondent-ils aux priorités du destinataire en fonction de son poste de {person_job} ?",
                "key": ["service_value", "person_job"]
            },
            {
                "question": "La proposition de valeur reflète-t-elle une connaissance des compétences clés associées au poste de {person_job} ?",
                "key": ["service_value", "person_job"]
            },
            {
                "question": "Les avantages proposés répondent-ils aux attentes ou aux préoccupations professionnelles d’un {person_job} ?",
                "key": ["service_value", "person_job", "mail_context"]
            }
        ],
        "clarity": [
            {
                "question": "Les phrases sont-elles courtes et percutantes ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les termes utilisés sont-ils compréhensibles pour une audience non experte ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Y a-t-il des expressions ambiguës ou sujettes à confusion ?",
                "key": ["service_value"]
            },
            {
                "question": "Le message peut-il être compris dès une première lecture rapide ?",
                "key": ["service_value"]
            },
            {
                "question": "Les informations secondaires ne diluent-elles pas le message principal ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les idées sont-elles présentées dans un ordre logique et fluide ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les transitions entre les différentes parties du message sont-elles naturelles ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message évite-t-il les répétitions inutiles ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les arguments ou bénéfices principaux sont-ils clairement hiérarchisés ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le texte suit-il un schéma clair (ex : problème, solution, bénéfices) ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les informations fournies sont-elles pertinentes pour comprendre la proposition ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les détails présentés renforcent-ils clairement les bénéfices mentionnés ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "L’email évite-t-il les informations superflues ou hors sujet ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les exemples utilisés sont-ils précis et adaptés au rôle de {person_job} ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "Le niveau de détail est-il suffisant sans être excessif ?",
                "key": ["service_value"]
            },
            {
                "question": "La proposition de valeur est-elle en cohérence avec l’accroche de l’email ?",
                "key": ["accroche", "service_value"]
            },
            {
                "question": "Les arguments ou bénéfices s’appuient-ils sur des points évoqués auparavant ?",
                "key": ["accroche", "service_value", "sender_value_proposition"]
            },
            {
                "question": "Les références éventuelles aux publications LinkedIn sont-elles bien intégrées ?",
                "key": ["service_value", "mail_context"]
            },
            {
                "question": "L’ensemble du message est-il cohérent et aligné du début à la fin ?",
                "key": ["accroche", "transition", "service_value"]
            },
            {
                "question": "La proposition de valeur est-elle visuellement distincte du reste du message ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les phrases et listes sont-elles correctement espacées pour une lecture fluide ?",
                "key": ["service_value"]
            },
            {
                "question": "Le formatage (paragraphes, ponctuation) facilite-t-il une lecture rapide ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "L’objectif final du message est-il clair du début à la fin ?",
                "key": ["accroche", "mail_context", "service_value"]
            },
            {
                "question": "Le ton employé est-il naturel et conversationnel ?",
                "key": ["service_value"]
            },
            {
                "question": "Les répétitions inutiles sont-elles évitées ?",
                "key": ["service_value"]
            }
        ],
        "emotional_impact": [
            {
                "question": "La proposition s’adresse-t-elle à un besoin pour un {person_job} ?",
                "key": ["service_value", "person_job"]
            },
            {
                "question": "La proposition de valeur fait-elle appel à des émotions positives ?",
                "key": ["service_value"]
            },
            {
                "question": "Les bénéfices évoqués dans la proposition sont-ils en phase avec les motivations du lecteur (exemple : gain de temps, sécurité, objectif, efficacité) ?",
                "key": ["service_value"]
            },
            {
                "question": "La proposition de valeur évoque-t-elle des termes ou concepts pertinents pour les {company_domain_activity} ?",
                "key": ["mail_context", "service_value"]
            },
            {
                "question": "Le langage utilisé est-il engageant et évocateur ?",
                "key": ["service_value"]
            },
            {
                "question": "La proposition contient-elle des mots qui inspirent confiance ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les phrases en général sont-elles chaleureuses ?",
                "key": ["service_value"]
            },
            {
                "question": "La proposition s’adresse-t-elle directement à {name} ?",
                "key": ["service_value"]
            },
            {
                "question": "Le contenu donne-t-il l’impression que l’émetteur comprend les besoins spécifiques du destinataire ?",
                "key": ["service_value", "mail_context", "person_job", "sender_value_proposition"]
            },
            {
                "question": "Y a-t-il une tentative de personnalisation émotionnelle ?",
                "key": ["service_value", "person_job", "mail_context", "sender_value_proposition"]
            },
            {
                "question": "L’approche semble-t-elle sincère et authentique dans sa manière de se connecter au lecteur ?",
                "key": ["service_value"]
            },
            {
                "question": "Le message montre-t-il que l’émetteur accorde une importance particulière aux valeurs ou attentes du destinataire ?",
                "key": ["service_value", "company_value", "person_job", "sender_value_proposition", "mail_context"]
            },
            {
                "question": "Les bénéfices évoqués sont-ils présentés de manière à stimuler le désir de passer à l’action ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message met-il en avant un sentiment d’urgence ou d’opportunité ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les bénéfices évoqués semblent-ils réalistes et atteignables, sans exagération ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le texte contient-il des éléments de réassurance ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "L’émetteur évoque-t-il des réussites ou résultats concrets pour renforcer la crédibilité ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "La manière de formuler la proposition inspire-t-elle confiance et professionnalisme ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le vocabulaire utilisé est-il vivant et expressif plutôt que générique et plat ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les adjectifs utilisés soulignent-ils les bénéfices de manière efficace et percutante ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "L’email donne-t-il au lecteur l’impression d’être traité comme un individu unique ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message met-il en avant les conséquences positives d’une action immédiate ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message donne-t-il au lecteur une bonne raison de répondre rapidement ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message contient-il une preuve de valeur ou un témoignage ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les engagements ou garanties évoqués sont-ils clairs et crédibles ?",
                "key": ["service_value", "sender_value_proposition"]
            }
        ],
        "personalization": [
            {
                "question": "La proposition mentionne-t-elle un besoin spécifique du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "L’offre est-elle alignée avec les objectifs principaux du destinataire ou de {company} ?",
                "key": ["service_value", "sender_value_proposition", "company_value"]
            },
            {
                "question": "La proposition montre-t-elle que l’émetteur a fait des recherches sur {name} ou {company} ?",
                "key": ["service_value", "company_domain_activity"]
            },
            {
                "question": "Les solutions proposées sont-elles pertinentes pour le domaine de {company_domain_activity} ?",
                "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
            },
            {
                "question": "La proposition fait-elle référence à des éléments propres au {person_job} ou aux responsabilités de {name} ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "Les besoins liés à la taille ou au modèle économique de {company} sont-ils pris en compte ?",
                "key": ["service_value", "company_domain_activity", "company_name"]
            },
            {
                "question": "L’émetteur montre-t-il qu’il comprend les processus ou les structures internes de l’entreprise ?",
                "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
            },
            {
                "question": "La proposition fait-elle référence à des points de douleur ou des opportunités spécifiques à l’industrie de {company} ?",
                "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
            },
            {
                "question": "La proposition de valeur est-elle formulée pour répondre à des défis ou des besoins d'entreprise spécifiques ?",
                "key": ["service_value", "sender_value_proposition", "company_value"]
            },
            {
                "question": "La solution proposée s’ajuste-t-elle aux priorités de l’entreprise du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "company_value"]
            },
            {
                "question": "La proposition de valeur est-elle ajustée en fonction du rôle ou du poste du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "Le contenu du message reflète-t-il une compréhension du rôle du destinataire dans l’entreprise ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "La proposition de valeur s'adresse-t-elle spécifiquement aux besoins liés au poste du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "L'email personnalise-t-il la solution en fonction des priorités stratégiques du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "company_value"]
            },
            {
                "question": "Des publications ou partages récents du destinataire sont-ils intégrés dans la proposition ?",
                "key": ["service_value", "sender_value_proposition", "mail_context"]
            },
            {
                "question": "La proposition inclut-elle une reconnaissance des actions ou décisions prises récemment par le destinataire ?",
                "key": ["service_value", "sender_value_proposition", "mail_context"]
            },
            {
                "question": "Les exemples ou illustrations utilisés sont-ils pertinents par rapport aux intérêts personnels ou professionnels du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "person_job"]
            },
            {
                "question": "La proposition évoque-t-elle un partenariat ou une collaboration possible basé sur des intérêts communs ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message mentionne-t-il des outils ou méthodes spécifiques utilisés par le destinataire ou son entreprise ?",
                "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
            },
            {
                "question": "Le niveau de détail est-il suffisant pour montrer une compréhension approfondie des besoins du destinataire ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "La proposition de valeur inclut-elle des termes ou expressions propres au secteur d’activité du destinataire ?",
                "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
            },
            {
                "question": "La personnalisation semble-t-elle authentique et non générique ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Le message évite-t-il les phrases standardisées ou les formulations trop générales ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "L’émetteur montre-t-il un intérêt sincère pour les besoins du destinataire ?",
                "key": ["service_value", "sender_value_proposition"]
            },
            {
                "question": "Les arguments présentés semblent-ils uniques et spécialement conçus pour ce destinataire ?",
                "key": ["service_value", "sender_value_proposition"]
            }
        ]
    }
}