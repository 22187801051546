import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";

import "../../styles/Campagne.css"
import PageGenerationProps from "../../interface/PageInterface";
import TableauStyle from "../../components/Aurelia/StyledTable";
import { EXCLUDE_LOCALISATION_KEY, FOLLOW_UP_DATA, INCLUDE_LOCALISATION_KEY, MAIL_CHECKBOX_KEY, MAIL_LANGUAGE_KEY, PROMPT2LLM_KEY, USER_ID_KEY, USER_INFO_KEY, V2_MAIL_KEY, V2_RECIPIENT_KEY, V2_SENDER_KEY } from "../../constants/StoreKey";
import { MailHistDataType } from "../../interface/DataI";
import { getUserCampaignHistory } from "../../services/UserServices";
import { LoaderResponse } from "../../components/Loader";
import { sendFrontEndData } from "../../api/connect2llm";
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import { decryptData } from "../../utils/util_function";
import { getUserCreditPricingInfo } from "../../api/tarifs_api";
import { COLORS } from "../../constants/ColorsData";

const LLM_NAME = "Aurélia"

const SuiviCampagne: React.FC<PageGenerationProps> = ({ creditValue, creditUpdater }) => {
    const history_image = "/images/btn/history-campagne.png"
    const refresh_icon = "/images/btn/refresh.png"
    const down_arrow_icon = "/images/btn/down-arrows.png"
    const up_arrow_icon = "/images/btn/up-arrows.png"
    const viz_icon = "/images/btn/viz.png"

    const [userID, setID] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [totalCredit, setTotalCredit] = useState(creditValue)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [hideTwoButton, setHideTwoButton] = useState<boolean>(false);
    const [runLoader, setRunLoader] = useState<boolean>(true); // Loader fonctionne à chaque validation
    const [emptyField, setEmptyField] = useState({
        state1: false
    })
    const [mailFieldNumber, setMailFieldNumber] = useState(totalCredit === 0 ? 0 : 1)

    const [mailHistoryData, setMailHistoryData] = useState<Array<MailHistDataType>>([])

    const [statusMailNumberAndText, setStatusMailNumberAndText] = useState<string>(String(mailFieldNumber).concat(" mail / ") + String(totalCredit).concat(totalCredit < 2 ? " crédit restant" : " crédits restants"));
    const [modalInfo2Display, setModalInfo2Display] = useState({
        header_title: "Préparation du lancement de campagne",
        description: "Dans cette section, vous allez pouvoir spécifier le nombre d'email à envoyer avant la validation finale",
        buttonText: ["S'inscrire", "Valider & envoyer"],
        loading_text: ["Redirection en cours...", "Envoi en cours..."],
        hide_input_content: false
    })

    const closeModal = () => {
        setShowModal(!showModal)
        setMailFieldNumber(totalCredit === 0 ? 0 : 1)
    }
    const toggleModal = () => {
        const userInfoData = localStorage.getItem(USER_INFO_KEY)

        if (userInfoData) {
            var email = JSON.parse(userInfoData)['email']
        }

        getUserCreditPricingInfo(email)
            .then((rep) => {
                if (rep && rep.data && rep.data.body) {
                    const body: any = rep.data.body
                    const new_credit_count = body.credit_count
                    const status_nb_mail_text = String(totalCredit === 0 ? 0 : 1).concat(" mail / ") + String(new_credit_count).concat(new_credit_count > 1 ? " crédits restants" : " crédit restant")

                    setTotalCredit(new_credit_count)
                    setStatusMailNumberAndText(status_nb_mail_text)
                    creditUpdater(new_credit_count)
                    if (new_credit_count === 0) setErrorMessage("Désolé, vous n'avez pas assez de crédit. Veuillez vous inscrire à partir de la page Tarifs")
                }
            })
            .catch((e) => { console.log("Credit verification error", e) })

        setShowModal(!showModal)
        setShowTable(false)
    }

    const validate = () => {
        if (mailFieldNumber === 0) {
            setEmptyField(prev => ({ ...prev, state1: true }))
            if (totalCredit === 0) setErrorMessage("Désolé, vous n'avez pas assez de crédit. Veuillez vous inscrire à partir de la page Tarifs")
            else setErrorMessage("Vous devez envoyer au moins un email")
            Redirect2NewPage("Aurélia", `Dashboard/Tarifs`)
        }
        else if (totalCredit === 0) {
            setErrorMessage("Désolé, vous n'avez pas assez de crédit. Veuillez vous inscrire à partir de la page Tarifs")
            Redirect2NewPage("Aurélia", `Dashboard/Tarifs`)
        } else {
            setErrorMessage("")
            setEmptyField(prev => ({ ...prev, state1: false }))

            // const prompt_base = localStorage.getItem(PROMPT2LLM_KEY)
            
            // if(prompt_base) console.log("Decoded prompt: ", decryptData(prompt_base))
            
            const recipient_data_v2 = localStorage.getItem(V2_RECIPIENT_KEY)
            const sender_data_v2 = localStorage.getItem(V2_SENDER_KEY)
            const mail_data_v2 = localStorage.getItem(V2_MAIL_KEY)
            if (recipient_data_v2 && sender_data_v2 && mail_data_v2) {
                let language = localStorage.getItem(MAIL_LANGUAGE_KEY)

                // const decode_prompt = decryptData(PROMPT2LLM_KEY).replace("mail_number_count", mailFieldNumber)

                const decoded_reciepient_data = decryptData(V2_RECIPIENT_KEY)
                const decoded_sender_data = decryptData(V2_SENDER_KEY)
                const decoded_mail_data = decryptData(V2_MAIL_KEY)
                const campaign = {
                    "campaign_number": mailFieldNumber
                }
                const decoded_followup_data = decryptData(FOLLOW_UP_DATA)

                setRunLoader(true)
                
                const res = sendFrontEndData(
                    userID, 
                    LLM_NAME, 
                    language ? JSON.parse(language) : JSON.parse('{ "name": "Français","classname":"fr"}'), 
                    decoded_reciepient_data, 
                    decoded_sender_data, 
                    decoded_mail_data, 
                    campaign,
                    decoded_followup_data
                )

                res.then(() => {
                    setShowModal(!showModal);
                    localStorage.removeItem(PROMPT2LLM_KEY)
                    localStorage.removeItem(MAIL_CHECKBOX_KEY)
                    localStorage.removeItem(INCLUDE_LOCALISATION_KEY)
                    localStorage.removeItem(EXCLUDE_LOCALISATION_KEY)
                    localStorage.removeItem(V2_RECIPIENT_KEY)
                    localStorage.removeItem(V2_SENDER_KEY)
                    localStorage.removeItem(V2_MAIL_KEY)
                    localStorage.removeItem(PROMPT2LLM_KEY)
                    Redirect2NewPage(LLM_NAME, "/info")
                }).catch((e) => {
                    console.error("Sending prompt error: ", e)
                })
            } else {
                setRunLoader(false)
                
                setModalInfo2Display(prevs => ({
                    ...prevs,
                    description: "Apparemment vous n'avez pas encore compléter les formulaires !",
                    header_title: "Oops!",
                    buttonText: ["S'inscrire", "Commencer"],
                    loading_text: ["Redirection en cours...", "Nous allons vous rediriger..."],
                    hide_input_content: true
                }))
                
                setTimeout(() => {
                    Redirect2NewPage(LLM_NAME, "Dashboard/Destinataire")
                }, 4000)
            }
        }
    }

    const updateMailNumberCount = (val: React.ChangeEvent<HTMLInputElement>) => {
        const field_value = Number(val.target.value)
        setMailFieldNumber(field_value)

        const status_nb_mail_text = String(field_value).concat(field_value > 1 ? " mails / " : " mail / ") + String(totalCredit).concat(totalCredit > 1 ? " crédits restants" : " crédit restant")
        setStatusMailNumberAndText(status_nb_mail_text)
    }

    const childrenView = () => {
        return (
            <div className="campagne-modal-container">
                <p style={{ textAlign: "center", fontSize: "larger" }}>{modalInfo2Display.description}</p>
                {
                    !modalInfo2Display.hide_input_content && (
                        <div className="campagne-modal-input-btn-mailnumber">
                            <label style={{ fontWeight: "normal", color: "black" }}>{statusMailNumberAndText}</label>
                            <input
                                type="number"
                                className={emptyField.state1 ? "error-field input_text" : "no-error input_text"}
                                min={totalCredit > 1 ? 1 : 0}
                                max={totalCredit === 0 ? 0 : totalCredit >= 5 ? 5 : totalCredit}
                                value={mailFieldNumber}
                                onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateMailNumberCount(val)}
                            />
                        </div>
                    )
                }
                <p style={{ color: "red", textAlign: "center", paddingLeft: 15, paddingRight: 15 }}>{errorMessage}</p>
            </div>
        )
    }

    // eslint-disable-next-line
    const refreshHistoryData = () => {
        setRefreshing(false)
        getUserCampaignHistory(userID).then(
            (rep: any) => {

                if (rep.body && rep.body.length > 0) {
                    let temp_data_arr: Array<MailHistDataType> = rep.body

                    temp_data_arr.forEach((val: MailHistDataType, index: number) => {
                        val['id'] = index
                        if (index % 2 === 0) val['status'] = "row1"; else val['status'] = "row2"
                    })
                    setMailHistoryData(temp_data_arr)
                    setRefreshing(true)
                }
            }
        ).catch((e) => console.error("Campaign error: ", e))
    }

    const scaleHistoryTable = () => {
        setHideTwoButton(!hideTwoButton)
    }
    
    const goToCampagnDetails = () => {
        Redirect2NewPage("Aurélia", `Dashboard/CampaignHistory`)
    }

    useEffect(() => {
        const userid = localStorage.getItem(USER_ID_KEY)

        if (userid) {
            setID(userid)
            getUserCampaignHistory(userid).then(
                (rep: any) => {
                    if (rep.body && rep.body.length > 0) {
                        let temp_data_arr: Array<MailHistDataType> = rep.body

                        temp_data_arr.forEach((val: MailHistDataType, index: number) => {
                            val['id'] = index
                            if (index % 2 === 0) val['status'] = "row1"; else val['status'] = "row2"
                        })
                        setMailHistoryData(temp_data_arr)
                    }
                    setRefreshing(false)
                }
            ).catch((e) => console.error("Campaign error: ", e))
        }
    }, [refreshing])
    
    return (
        <div className="campagne-container scroll" style={{overflowY: "hidden"}}>
            {
                !hideTwoButton && (
                    <div className="campagne-two-btn">
                        <button disabled className="campagne-btn-container" onClick={toggleModal}>
                            <div className="campagne-btn-image">
                                <img src={viz_icon} alt={""} width={100} height={100} />
                            </div>
                            <div className="campagne-btn-title">Visualisation et résumé</div>
                        </button>

                        <button className="campagne-btn-container" onClick={goToCampagnDetails}>
                            <div className="campagne-btn-image">
                                <img className="campagne-btn-image" src={history_image} alt={""} width={100} height={100} />
                            </div>
                            <div className="campagne-btn-title">Historique des campagnes</div>
                        </button>
                        <Modal
                            open={showModal}
                            header_title={modalInfo2Display.header_title}
                            header_title_bg_color={COLORS.jauneClaire}
                            header_title_text_color="black"
                            buttonText={totalCredit === 0 ? modalInfo2Display.buttonText[0] : modalInfo2Display.buttonText[1]}
                            buttonBgColor={COLORS.jauneClaire}
                            button_text_color="black"
                            loading_text={totalCredit === 0 ? modalInfo2Display.loading_text[0] : modalInfo2Display.loading_text[1]}
                            loader_state={runLoader}
                            loader_color={COLORS.jauneClaire}
                            loading_text_color="black"
                            onClose={closeModal} onValid={validate}
                            children={childrenView()}></Modal>
                    </div>
                )
            }
            <div className="campagne-second-part" style={{ background: showTable ? COLORS.gris : 'transparent' }}>
                {
                    !showTable &&
                    <div style={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <div className="campagne-text">
                            <h1>Visualisation et résumé</h1>
                            <p>Vous permet de visualiser votre campagne, de voir les résumés quotidien, etc...</p>
                        </div>
                        <div className="campagne-text">
                            <h1>Historique des campagnes: </h1>
                            <p>L'endroit où vous pourrez suivre vos campagnes, après avoir envoyer vos mails</p>
                        </div>
                    </div>
                }

                {
                    showTable && (
                        <div className="campagne-tab-btn-ux">
                            <div className="campagne-tab-btn-icon-container" style={{flex:3}}></div>
                            <div className="campagne-tab-btn-icon-container">
                                <button className="campagne-tab-btn-icon" onClick={goToCampagnDetails}>
                                    <img src={hideTwoButton ? up_arrow_icon : down_arrow_icon } alt="" width={25} height={25} style={{ cursor: "pointer" }} />
                                    <label>{"Détails"}</label>
                                </button>
                            </div>
                            <div className="campagne-tab-btn-icon-container">
                                <button className="campagne-tab-btn-icon" onClick={scaleHistoryTable}>
                                    <img src={hideTwoButton ? down_arrow_icon: up_arrow_icon} alt="" width={25} height={25} style={{ cursor: "pointer" }} />
                                    <label>{hideTwoButton ? "Réduire": "Agrandir"}</label>
                                </button>
                            </div>
                            <div className="campagne-tab-btn-icon-container">
                                <button className="campagne-tab-btn-icon" onClick={refreshHistoryData}>
                                    <img src={refresh_icon} alt="R" width={25} height={25} style={{ cursor: "pointer" }} />
                                    <label>Actualiser</label>
                                </button>
                            </div>
                        </div>
                    )
                }
                {
                    refreshing ? (
                        <div style={{ display: "flex", flex: 5, justifyContent: "center", alignItems: "center" }}>
                            <LoaderResponse colorValue={COLORS.noireMoinsSombre} />
                            <p style={{ color: "black", fontWeight: 'bold' }}>Actualisation en cours...</p>
                        </div>
                    ) : (
                        showTable && <TableauStyle data={mailHistoryData} />
                    )
                }
            </div>
        </div>
    )
}

export default SuiviCampagne