import { useEffect, useState } from 'react'
import { LoaderResponse } from '../../components/Loader'
import HistoryCampaignTable, { ColumnNameType } from '../../components/Table/DataTable'
import { followUp_template } from '../../constants/ArrayData'
import { COLORS } from '../../constants/ColorsData'
import { USER_ID_KEY } from '../../constants/StoreKey'
import { getUserDetailedCampaignHistory } from '../../services/UserServices'

import '../../styles/CampaignHistoryPage.css'
import { CampaignMailDataType, UserCampaignDataType } from '../../type/CampaignMailType'

const column: Array<ColumnNameType> = [
    {
        colname: "id",
        labelname: "ID"
    },
    {
        colname: "user_id",
        labelname: "IDU"
    },
    {
        colname: "target_person_name",
        labelname: "Nom du cible"
    },
    {
        colname: "target_person_job",
        labelname: "Poste du cible"
    },
    {
        colname: "target_person_company_name",
        labelname: "Entreprise cible"
    },
    {
        colname: "mail_id",
        labelname: "IDM"
    },
    {
        colname: "mail_title",
        labelname: "Titre"
    },
    {
        colname: "mail_body",
        labelname: "Contenu"
    },
    {
        colname: "mail_status",
        labelname: "Etat du mail"
    },
    {
        colname: "mail_state_count",
        labelname: 'N° fois'
    },
    {
        colname: "sending_date",
        labelname: "Date d'envoi"
    }
]


const HistoryPage = () => {
    const [campagnHistoryData, setCampagnHistoryData] = useState<Array<UserCampaignDataType>>([])
    const [loader, setLoader] = useState(true)

    const convertCampaignMail2UserCampaignDataType = (campaignData: Array<CampaignMailDataType>) => {
        let userData: Array<UserCampaignDataType> = []

        campaignData.forEach((data, index) => {
            let latestHistory = {
                version: 0,
                title: "",
                body: "",
                extraction: {
                    summary: "",
                    priority : 0,
                    category: ""
                }
            }

            if(data['history'] && data['history'].length > 0) {
                latestHistory = data['history'][0]
            }
        
            try {
                userData.push({
                    id: data.id,
                    user_id: data.user_id,
                    target_person_name: data.target_person_name,
                    target_person_job: data.target_person_job,
                    target_person_company_name: data.target_person_company_name,
                    mail_context_category: data.mail_context ? data.mail_context.catégorie : "",
                    mail_context_priority: data.mail_context ? data.mail_context.priorité : 0,
                    mail_context_summary: data.mail_context && data.mail_context.original_post ? data.mail_context.original_post.postTitle : "",
                    mail_id: data.mail_id,
                    mail_title: data.mail_title,
                    mail_body: data.mail_body,
                    mail_status: data.mail_status,
                    mail_state_count: data.mail_state_count,
                    sending_date: data.sending_date,
                    Email: data.Email,
                    target_job: data.target_job,
                    evaluation_state: data.evaluation_state ? 1 : data.evaluation_state === null ? -1 : 0,
                    history_extraction: String(latestHistory.extraction),
                    history_title: latestHistory.title,
                    history_body: latestHistory.body,
                    history_version: latestHistory.version,
                })
            } catch(e) {
                console.error(`Error on index ${index}: ${e}`)
            }
        })

        return userData
    }

    useEffect(() => {
        const userid = localStorage.getItem(USER_ID_KEY)
        function convertToDate(dateStr: string) {
            const [day, month, yearAndTime] = dateStr.split("-");
            const [year, time] = yearAndTime.split(" ");
            return new Date(`${year}-${month}-${day}T${time}`);
        }
        if (userid) {
            getUserDetailedCampaignHistory(userid).then(
                (response: {
                    body: Array<CampaignMailDataType>
                }) => {

                    if (response) {
                        const body = convertCampaignMail2UserCampaignDataType(response.body)
                        let body_with_index: Array<UserCampaignDataType> = []
                        body.forEach((item, index) => {
                            item['id'] = index

                            const dateObj = convertToDate(item['sending_date'])
                            // Obtenir le temps en millisecondes, puis convertir en secondes
                            const totalSeconds = Math.floor(dateObj.getTime() / 1000);
                            item['sending_date'] = totalSeconds.toString()

                            body_with_index.unshift(item)
                        })
                        const reversed_data = body_with_index.reverse()
                        
                        setCampagnHistoryData(reversed_data)
                        if (campagnHistoryData.length > 0) setLoader(false)
                    }
                }).catch((e) => {
                    setLoader(false)
                })
        }
    }, [campagnHistoryData.length])

    useEffect(() => {
        if (campagnHistoryData.length > 0) setLoader(false)
    }, [loader, campagnHistoryData.length])

    return (
        <>
            {
                loader ? (
                    <div className="campagne-container" style={{ justifyContent: "center", alignItems: "center" }}>
                        <LoaderResponse colorValue={COLORS.jauneClaire} w={130} h={130} />
                        <p style={{ color: COLORS.jauneClaire }}>Chargement du tableau...</p>
                    </div>
                ) : (
                    <div className="campagn-history-main scroll">
                        <HistoryCampaignTable rows={campagnHistoryData} columnName={column} followUpTemplate={followUp_template} />
                    </div>
                )
            }
        </>
    )
}

export default HistoryPage