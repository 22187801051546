export const postCategoryOptions: Record<string, Record<string, Array<string>>> = {
    "Post publié" : {
        "Contenu professionnel": [
            "Succès & Parcours",
            "Partage d'expérience & Conseils",
            "Recommandations et témoignages",
            "Blagues et anecdotes"
        ],
        "Contenu personnel": [
            "Analyses et réflexions",
            "Tendances & Innovations"
        ],
        "Contenu sur la société ou produit": [
            "Promotion de produits ou services",
            "Stratégies & Branding",
            "Collaborations et partenariats"
        ],
        "Événements": [
            "Annonces d'Événements",
            "Retour d’expérience suite à un événement"
        ]
    },
    "Post republié" : {
        "Actualités" : [
            "Actualités marquantes"
        ],
        "Contenus individuels" : [
            "Histoires inspirantes",
            "Posts viraux",
            "Conseils professionnels",
            "Sujets engagés"
        ],
        "Post d’entreprise" : [
            "Annonces officielles",
            "Succès et reconnaissances",
            "Événements et conférences"
        ]
    },
    "Post partagé" : {

    },
    "Description profil LinkedIn" : {

    },
    "Valeur & service vendu" : {

    }
}