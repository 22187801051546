import React, { useState, useRef, useEffect } from 'react';

// Composant d'icône personnalisé pour remplacer ChevronDown de lucide-react
const ChevronDownIcon = ({ className = '', size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

// Définition du type pour les éléments de l'accordéon (dynamique)
export type AccordionItemProps = {
  title: string;
  content: string;
};

// Propriétés pour le composant principal
type AccordionProps = {
  items: AccordionItemProps[];
};

// Composant pour un seul élément de l'accordéon
const AccordionItem = ({ items }: { items: AccordionItemProps[] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLongContent, setIsLongContent] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Vérifier si le contenu est long après le rendu
  useEffect(() => {
    if (isOpen && contentRef.current) {
      setIsLongContent(contentRef.current.scrollHeight > 200);
    }
  }, [isOpen, items]);

  // Concaténer les titres et les contenus
  const combinedContent = items
    .map((item) => `<strong>${item.title}:</strong> ${item.content}`)
    .join('<br /><br />');

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h3>Contexte</h3>
        <ChevronDownIcon
          className={`chevron-icon ${isOpen ? 'rotate' : ''}`}
          size={20}
        />
      </div>

      {isOpen && (
        <div
          ref={contentRef}
          className={`accordion-content ${
            !isExpanded && isLongContent ? 'collapsed' : ''
          }`}
        >
          <div className="content-inner">
            <div dangerouslySetInnerHTML={{ __html: combinedContent }} />
          </div>

          {isLongContent && (
            <button className="expand-button" onClick={toggleExpand}>
              {isExpanded ? 'Voir moins' : 'Voir plus'}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

// Composant principal d'accordéon
const Accordion = ({ items = [] }: AccordionProps) => {
  return (
    <div className="accordion-container">
      <AccordionItem items={items} />
    </div>
  );
};

// Exemple d'utilisation avec différents types de données
const AccordionComponent = ({ detailedDataItems }: { detailedDataItems: AccordionItemProps[] }) => {
  return (
    <>
      <StyleInjector />
      <Accordion items={detailedDataItems} />
    </>
  );
};

// Styles CSS intégrés dans le composant
const styles = `
  .accordion-container {
    margin-top: 10px;
    margin-bottom: 10px;
    // width: 100%;
    // max-width: 800px;
    // margin: 0 auto;
    // padding: 20px;
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .accordion-title {
    font-size: 24px;
    // margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }
  
  .accordion-item {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .accordion-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .accordion-header:hover {
    background-color: #f1f3f5;
  }
  
  .accordion-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #2c3e50;
  }
  
  .chevron-icon {
    color: #6c757d;
    transition: transform 0.3s ease;
  }
  
  .chevron-icon.rotate {
    transform: rotate(180deg);
  }
  
  .accordion-content {
    background-color: #fff;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .accordion-content.collapsed {
    max-height: 200px;
    position: relative;
    // overflow: hidden;
    overflow-y: auto
  }
  
  .accordion-content.collapsed::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(transparent, #fff);
    pointer-events: none;
  }
  
  .content-inner {
    padding: 16px 20px;
  }
  
  .content-inner p {
    margin-top: 0;
    line-height: 1.6;
    color: #4a4a4a;
  }
  
  .additional-info {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;
  }
  
  .additional-info strong {
    color: #2c3e50;
    font-weight: 500;
  }
  
  .expand-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #f8f9fa;
    border: none;
    border-top: 1px solid #e0e0e0;
    color: #007bff;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .expand-button:hover {
    background-color: #e9ecef;
  }
`;

// Injecter les styles CSS
const StyleInjector = () => {
  return <style>{styles}</style>;
};

export default AccordionComponent;