import { Tooltip, Button, Typography, Box, ButtonGroup, Modal, TextField, Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Autocomplete, Chip, Switch } from "@mui/material";
import React, { useRef } from "react";
import { useState, ChangeEvent, useEffect } from "react";
import { getAllQuestionsByPostType, getCollectionName4BartEvaluation, saveNewCatAndSubWithQuestion, sendMailEvaluation4Bart, updateScoringData4Feedback } from "../../api/scoring";
import AccordionComponent, { AccordionItemProps } from "../../components/Accordeon/MyAccordeon";
import FeedbackChoice from "../../components/Button/FeedbackButton";
import { LoaderResponse } from "../../components/Loader";
import { postCategoryOptions } from "../../constants/category/dico";
import { COLORS } from "../../constants/ColorsData";
import { bartKey2Text, criteria_key2value } from "../../constants/criteria/dico";
import { accrocheDefaultData } from "../../constants/questions_list/AccrocheMailSegmentData";
import { defaultSegmentData } from "../../constants/questions_list/MailSegmentData";
import { BART_FORM_DATA, USER_ID_KEY } from "../../constants/StoreKey";
import { PRIORITE1_POST_TYPE_1, PRIORITE1_POST_TYPE_2, PRIORITE1_POST_TYPE_3, PRIORITE2_DESC_TYPE_1, PRIORITE3_SV_TYPE_1 } from "../../constants/textVariable";
import "../../styles/BartTestEmailForm.css";
import { decryptData, encryptData } from "../../utils/util_function";

import AddIcon from '@mui/icons-material/Add';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { LoadingButton } from "@mui/lab";
import { customStyle } from "../../components/Style/ModalStyle";
import { mailSegmentDico, mailSegmentKeys } from "../../constants/mail_segment/dico";
import { AnyARecord } from "dns";

interface QuestionData {
    question: string;
    key: string[];
}

interface QuestionList {
    [criteria: string]: QuestionData[];
}

type DataDict = { [key: string]: string };

var default_mail_segment_key = "accroche"
var default_criteria = "pertinence"
var default_post_category = "Post publié"
var default_question = ""


const BartTestEmailForm = () => {
    const resultRef = React.useRef<HTMLDivElement | null>(null);
    const feedBackContainerRef = React.useRef<HTMLDivElement | null>(null);

    // Initialisation des états avec des valeurs par défaut
    const [formData, setFormData] = useState({
        fullMail: "",
        mailSegment: {
            salutation: "",
            accroche: "",
            transition: "",
            service_value: "",
            cta: "",
        },
        mailContext: "",
        priorite: "1",
        postCategory: default_post_category,
        person_job: "",
        company_name: "",
        target_company_value: "",
        target_company_activity: "",
        sender_value_proposition: "",
        mailSegmentKey: default_mail_segment_key,
        selectedCriteria: default_criteria,
        selectedQuestion: default_question,
        selectedCategory: "Contenu professionnel",
        selectedSubcategory: "Succès & Parcours"
    });

    // Les termes à changer/modifier dans une questions car des valeurs dynamiques
    const [dataDict, setDataDict] = useState<DataDict>({
        name: "name",
        person_job: "person_job",
        company: "company_name",
        company_domain_activity: "company_domain_activity",
    });

    const [evalResultProcess, setEvalResultProcess] = useState(false)

    const [questionsData, setQuestionData] = useState<QuestionList>(accrocheDefaultData[default_mail_segment_key])
    const [contextType, setContextType] = useState(default_post_category)
    const [jsonQuestionData, setJsonQuestionData] = useState()
    const [currentQuestions, setCurrentQuestions] = useState<QuestionData[]>([]);
    const [evaluationResults, setEvaluationResults] = useState<any[]>([]);
    const [runLoader, setRunLoader] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [hideQuestionList, setHideQuestionList] = useState(false)
    const [runFeedbackLoader, setRunFeedbackLoader] = useState<Array<boolean>>([]);
    const [modal2show, setModal2show] = useState<"add" | "update" | "none">("none");
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openUpdateModal, setUpdateModal] = useState(false)
    const [ButtonLoader, setButtonLoader] = React.useState(false)
    const [textFields, setTextFields] = useState<JSX.Element[]>([]);

    const questionsByCriteria = (mailSegmentKey: string, dico: DataDict): QuestionList => {
        const category_: string = formData.selectedCategory;
        const subcategory: string = formData.selectedSubcategory;

        // Remplacer les key par leur valeur
        const replacePlaceholders = (format: QuestionList | undefined, values: DataDict): QuestionList => {
            if (!format) return {}; // Add this guard clause to handle undefined format

            return Object.fromEntries(
                Object.entries(format).map(([category, items]) => [
                    category,
                    items.map((item) => ({
                        question: item.question.replace(/{(\w+)}/g, (_, key) => values[key] || ""),
                        key: item.key
                    })),
                ])
            );
        };

        // Check if accrocheDefaultData[mailSegmentKey] exists before using it
        const default_data: QuestionList = accrocheDefaultData[mailSegmentKey] ?
            replacePlaceholders(accrocheDefaultData[mailSegmentKey], dico) : {};

        if (jsonQuestionData) {
            try {
                if (jsonQuestionData[category_] && jsonQuestionData[category_][subcategory] && jsonQuestionData[category_][subcategory][mailSegmentKey]) {
                    let dataFormat = jsonQuestionData[category_][subcategory][mailSegmentKey];
                    const updatedDataFormat: QuestionList = replacePlaceholders(dataFormat, dico);
                    // console.log("Json questions : ", updatedDataFormat)
                    if (Object.keys(updatedDataFormat).length > 0) {
                        return updatedDataFormat;
                    }
                }
                return default_data
            } catch (error) {
                // console.error(`Error loading questions for ${subcategory}:`, error);
                return default_data;
            }
        }

        // if (mailSegmentKey === "accroche") {
        //     try {
        //         const data = require(`./questions/${contextType}/${formData.selectedCategory}/${subcategory}.json`);

        //         // Check if data structure exists before accessing
        //         if (data && data[subcategory] && data[subcategory][mailSegmentKey]) {
        //             let dataFormat = data[subcategory][mailSegmentKey];
        //             const updatedDataFormat: QuestionList = replacePlaceholders(dataFormat, dico);

        //             if (Object.keys(updatedDataFormat).length > 0) {
        //                 return updatedDataFormat;
        //             }
        //         }

        //         return default_data;
        //     } catch (error) {
        //         // console.error(`Error loading questions for ${subcategory}:`, error);
        //         return default_data;
        //     }
        // } 
        else {
            // Check if defaultSegmentData[mailSegmentKey] exists before using it
            return defaultSegmentData[mailSegmentKey] ?
                replacePlaceholders(defaultSegmentData[mailSegmentKey], dico) : {};
        }
    };

    useEffect(() => {
        // Chargement de toutes les questions depuis la BDD
        const postType_qst_data = decryptData(formData.postCategory)
        if (postType_qst_data === null) {
            getAllQuestionsByPostType({
                "postType": formData.postCategory
            }).then((response) => {
                if (response && response.data && response.data.body) {
                    encryptData(formData.postCategory, response.data.body)
                    setJsonQuestionData(response.data.body)
                }
            }).catch((e) => console.log(e))
        } else {
            // console.log("ato @else", postType_qst_data)
            setJsonQuestionData(postType_qst_data)
            const storedData = decryptData(BART_FORM_DATA);
            if (storedData) {
                // Préparation des valeurs du formulaire, dynamisation de clé dans la question
                try {
                    const parsedData = JSON.parse(storedData);
                    // setFormData(parsedData);

                    const temp_data = {
                        name: parsedData.mailSegment.salutation?.split(" ")[1] || "",
                        company: parsedData.company_name || "",
                        person_job: parsedData.person_job || "",
                        company_domain_activity: parsedData.target_company_activity || ""
                    };

                    // Récupération des questions associées au segment
                    const segment = parsedData.mailSegmentKey || default_mail_segment_key;

                    const questionsDataFromStorage = questionsByCriteria(segment, temp_data);
                    // Mettre à jour les valeurs des clés à remplacer dans les questions {company}, {name}
                    setDataDict(temp_data);
                    setQuestionData(questionsDataFromStorage);

                    const associatedCriteria = parsedData.selectedCriteria || getFirstCriteriaKey(segment);
                    const associatedQuestionList = questionsDataFromStorage[associatedCriteria] || [];

                    // Sélection de la question : celle du stockage ou la première question disponible
                    const selectedQuestion = parsedData.selectedQuestion && parsedData.selectedQuestion.trim() !== ""
                        ? parsedData.selectedQuestion
                        : (associatedQuestionList.length > 0 ? associatedQuestionList[0].question : "");

                    setCurrentQuestions(associatedQuestionList);

                    setFormData((prev) => ({
                        ...prev,
                        selectedCriteria: associatedCriteria,
                        selectedQuestion: selectedQuestion
                    }));
                } catch (error) {
                    // console.error("Error parsing stored data:", error);
                    initializeDefaultQuestions();
                }
            }
        }
    }, [formData.postCategory])

    // Chargement depuis localStorage des valeurs des champs du formulaire et initialisation des questions
    useEffect(() => {
        const storedData = decryptData(BART_FORM_DATA);
        if (storedData) {
            // Préparation des valeurs du formulaire, dynamisation de clé dans la question
            try {
                const parsedData = JSON.parse(storedData);
                setFormData(parsedData);

                const temp_data = {
                    name: parsedData.mailSegment.salutation?.split(" ")[1] || "",
                    company: parsedData.company_name || "",
                    person_job: parsedData.person_job || "",
                    company_domain_activity: parsedData.target_company_activity || ""
                };

                // Récupération des questions associées au segment
                const segment = parsedData.mailSegmentKey || default_mail_segment_key;

                const questionsDataFromStorage = questionsByCriteria(segment, temp_data);
                // Mettre à jour les valeurs des clés à remplacer dans les questions {company}, {name}
                setDataDict(temp_data);
                setQuestionData(questionsDataFromStorage);

                const associatedCriteria = parsedData.selectedCriteria || getFirstCriteriaKey(segment);
                const associatedQuestionList = questionsDataFromStorage[associatedCriteria] || [];

                // Sélection de la question : celle du stockage ou la première question disponible
                const selectedQuestion = parsedData.selectedQuestion && parsedData.selectedQuestion.trim() !== ""
                    ? parsedData.selectedQuestion
                    : (associatedQuestionList.length > 0 ? associatedQuestionList[0].question : "");

                setCurrentQuestions(associatedQuestionList);

                setFormData((prev) => ({
                    ...prev,
                    selectedCriteria: associatedCriteria,
                    selectedQuestion: selectedQuestion
                }));
            } catch (error) {
                // console.error("Error parsing stored data:", error);
                initializeDefaultQuestions();
            }
        } else {
            initializeDefaultQuestions();
        }
    }, []);

    // Function to initialize default questions
    const initializeDefaultQuestions = () => {
        const initialQuestionsData = questionsByCriteria(default_mail_segment_key, dataDict);
        const initialQuestions = initialQuestionsData[default_criteria] || [];
        setCurrentQuestions(initialQuestions);
        setQuestionData(initialQuestionsData);
    };

    const categories: Record<string, string[]> = {
        "1": [PRIORITE1_POST_TYPE_1, PRIORITE1_POST_TYPE_2, PRIORITE1_POST_TYPE_3],
        "2": [PRIORITE2_DESC_TYPE_1],
        "3": [PRIORITE3_SV_TYPE_1],
    };

    const mailSegmentValue: Record<string, string> = {
        "accroche": "Accroche",
        "transition": "Transition",
        "service_value": "Proposition de valeur"
    };

    const getSegmentMailCriteriaKeys = (mail_segment_key: string) => {
        const qsd = questionsByCriteria(mail_segment_key, dataDict)
        const keys: Array<string> = Object.keys(qsd)
        let criteriaKeys: Record<string, string> = {}

        keys.forEach((value, index) => {
            criteriaKeys[value] = criteria_key2value[value]
        })

        return criteriaKeys
    }

    const criteriaOptions: Record<string, Record<string, string>> = {
        "accroche": getSegmentMailCriteriaKeys("accroche"),
        "transition": getSegmentMailCriteriaKeys("transition"),
        "service_value": getSegmentMailCriteriaKeys("service_value"),
    };

    const getFirstCriteriaKey = (segment: string): string => {
        const options = criteriaOptions[segment];
        return options ? Object.keys(options)[0] : default_criteria;
    };

    const handleChangeRecipient = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const trim_value = value.trim()

        setFormData((prev) => ({ ...prev, [name]: trim_value }));

        switch (name) {
            case "person_job":
                setDataDict((prev) => ({ ...prev, person_job: trim_value }));
                break
            case "company_name":  // Fixed property name to match input field
                setDataDict((prev) => ({ ...prev, company: trim_value }));
                break
            case "target_company_activity":  // Fixed property name
                setDataDict((prev) => ({ ...prev, company_domain_activity: trim_value }));
                break
            default:
                break
        }
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const trim_value = value.trim()

        if (name === "fullMail") {
            const mail_split_data = trim_value.trim().split("\n\n")

            const salutation = mail_split_data[0] || ""
            const accroche_transition = mail_split_data[1] || ""
            const proposition = mail_split_data[2] || ""
            const cta = mail_split_data[3] || ""

            setFormData((prev) => ({
                ...prev,
                fullMail: trim_value,
                mailSegment: {
                    salutation: salutation,
                    accroche: accroche_transition.split(".")[0] || "",
                    transition: accroche_transition.split(".")?.[1] || "",
                    service_value: proposition,
                    cta: cta
                }
            }))

            setDataDict((prev) => ({ ...prev, name: salutation.split(" ")?.[1] || "" }));
        }
        // Type de post choisi
        if (name === "postCategory" && trim_value !== "Post partagé") {
            setContextType(trim_value)
            // Récupérer la liste des clés par rapport au type de post (publié, republié, partagé)
            const keysFromPostType = Object.keys(postCategoryOptions[trim_value])
            // Utiliser la 1ère clé
            setFormData((prev) => ({
                ...prev,
                selectedCategory: keysFromPostType[0],
                selectedSubcategory: postCategoryOptions[trim_value][keysFromPostType[0]][0] // 1ère sous-catégorie
            }));
        }

        // Mettre à jour la valeur choisie de formData
        setFormData((prev) => ({ ...prev, [name]: trim_value }));
    };

    const disableAllquestions = () => {
        setHideQuestionList(!hideQuestionList)
    }

    const handleMailSegmentChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target;

        // Make sure questionsData exists
        if (questionsData && Object.keys(questionsData).length > 0) {
            const newCriteria = getFirstCriteriaKey(value);
            var newQuestions = questionsData[newCriteria];

            // Si la valeur est undefined, on recharge les questions à partir de la clé segment de mail (value)
            if (!newQuestions) {
                const newQuestionsData = questionsByCriteria(value, dataDict)
                newQuestions = newQuestionsData[newCriteria]
            }

            setCurrentQuestions(newQuestions);

            setFormData((prev) => ({
                ...prev,
                mailSegmentKey: value,
                selectedCriteria: newCriteria,
                selectedQuestion: newQuestions.length > 0 ? newQuestions[0].question : ""
            }));
        } else {
            // If questionsData is empty, try to get it
            const newQuestionsData = questionsByCriteria(value, dataDict);
            setQuestionData(newQuestionsData);

            const newCriteria = getFirstCriteriaKey(value);
            const newQuestions = newQuestionsData[newCriteria] || [];
            setCurrentQuestions(newQuestions);

            setFormData((prev) => ({
                ...prev,
                mailSegmentKey: value,
                selectedCriteria: newCriteria,
                selectedQuestion: newQuestions.length > 0 ? newQuestions[0].question : ""
            }));
        }
    };

    const handleCategoryChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const trim_value = value.trim()

        const temp_data = {
            name: formData.mailSegment.salutation?.split(" ")[1] || "",
            company: formData.company_name || "",
            person_job: formData.person_job || "",
            company_domain_activity: formData.target_company_activity || ""
        }

        if (name === "selectedCategory") setFormData(prev => ({ ...prev, selectedSubcategory: postCategoryOptions[prev.postCategory][trim_value][0] }))

        const newQuestionsData = questionsByCriteria(formData.mailSegmentKey, temp_data)
        const newQuestions = newQuestionsData && newQuestionsData[formData.selectedCriteria] ? newQuestionsData[formData.selectedCriteria] : [];

        setCurrentQuestions(newQuestions);
        setDataDict(temp_data)
        setFormData((prev) => ({
            ...prev,
            [name]: trim_value,
            selectedQuestion: newQuestions.length > 0 ? newQuestions[0].question : ""
        }));
    };

    const handleCriteriaChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target;
        const trim_value = value.trim()

        const temp_data = {
            name: formData.mailSegment.salutation?.split(" ")[1] || "",
            company: formData.company_name || "",
            person_job: formData.person_job || "",
            company_domain_activity: formData.target_company_activity || ""
        }
        setDataDict(temp_data)

        // Make sure questionsData exists and has the selected criteria
        const newQuestions = questionsData && questionsData[value] ? questionsData[trim_value] : [];
        setCurrentQuestions(newQuestions);

        setFormData((prev) => ({
            ...prev,
            selectedCriteria: trim_value,
            selectedQuestion: newQuestions.length > 0 ? newQuestions[0].question : ""
        }));
    };

    const handleMailDecomposition = (): {
        salutation: string;
        accroche: string;
        transition: string;
        service_value: string;
        cta: string;
    } => {
        const fullMail = formData.fullMail;
        let paragraphs: string[] = [];

        if (fullMail.includes("\n\n")) {
            paragraphs = fullMail
                .split("\n\n")
                .map((p) => p.trim())
                .filter((p) => p.length > 0);
        } else {
            paragraphs = fullMail
                .split("\n")
                .map((p) => p.trim())
                .filter((p) => p.length > 0);
        }

        let salutation = "";
        let accroche = "";
        let transition = "";
        let service_value = "";
        let cta = "";

        if (paragraphs.length > 0) {
            salutation = paragraphs[0];
        }

        if (paragraphs.length > 1) {
            const part = paragraphs[1];
            const separatorIndex = part.indexOf(". ");
            if (separatorIndex !== -1) {
                accroche = part.substring(0, separatorIndex + 1).trim();
                transition = part.substring(separatorIndex + 1).trim();
            } else {
                accroche = part;
            }
        }

        if (paragraphs.length > 2) {
            service_value = paragraphs[2];
        }

        if (paragraphs.length > 3) {
            cta = paragraphs[3];
        }

        return { salutation, accroche, transition, service_value, cta };
    };

    /**
     * Récupérer le contexte ou informations utilisés avec les questions pendant l'évaluation
     * @param currentEvalQuestion 
     * @returns 
     */
    const getContextDataFromList = (currentEvalQuestion: string) => {
        if (questionsData[formData.selectedCriteria]) {
            const question_and_key_data = questionsData[formData.selectedCriteria].filter(
                (q: any) => String(q.question).trim() === currentEvalQuestion.trim()
            )

            // Segmentation de mail
            const mailSegmented: any = handleMailDecomposition();

            // Dictionnaire de valeur
            let contextDico: any = {
                "mail_segment": formData.mailSegmentKey,
                "mail_context": formData.mailContext,
                "person_job": formData.person_job,
                "company_name": formData.company_name,
                "company_value": formData.target_company_value,
                "sender_value_proposition": formData.sender_value_proposition
            }
            // Mettre à jour la valeur du segment de mail courant
            contextDico[formData.mailSegmentKey] = mailSegmented[String(formData.mailSegmentKey)]

            let AccordeonDataItem: Array<AccordionItemProps> = []

            question_and_key_data.map((item: { question: string, key: Array<string> }) => {
                item.key.forEach((element: string, index: number) => {
                    AccordeonDataItem.push({
                        title: bartKey2Text[element],
                        content: contextDico[element] ? contextDico[element] : mailSegmented[element]
                    })
                })
            })

            return AccordeonDataItem
        } else return []
    }

    const sendData2Server = (data2send: any) => {
        sendMailEvaluation4Bart(data2send).then((rep: any) => {
            const response_data_body = rep.data.body;

            if (response_data_body && Array.isArray(response_data_body)) {
                setEvaluationResults(prevResults => [...prevResults, ...response_data_body]); // Met à jour l'affichage des résultats
                // Ajout d'un nouveau status loader
                setRunFeedbackLoader((prevState) => [...prevState, false]);
                setShowResult(true)

                setTimeout(() => {
                    resultRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 100);
            }

            setRunLoader(false);
        }).catch((error) => {
            console.error("Erreur lors de l'envoi :", error);
            setRunLoader(false);
        });
    }
    const handleSubmit = () => {
        setEvaluationResults([])
        const mailSegmented = handleMailDecomposition();

        const input_data = {
            mail_segment: mailSegmented,
            summary: {
                "priorité": Number(formData.priorite),
                "catégorie": formData.postCategory,
                "résumé": formData.mailContext,
            },
            target_company_name: formData.company_name,
            target_company_value: formData.target_company_value,
            target_company_activity: formData.target_company_activity,
            person_job: formData.person_job,
            sender_value_proposition: formData.sender_value_proposition,
        };
        // console.log("In : ", input_data)
        // return
        // Sauvegarder les données dans localStorage à chaque changement
        encryptData(BART_FORM_DATA, JSON.stringify(formData));

        let question_data: Record<string, { question: string; key: string[] }[]> = {};

        if (currentQuestions) {
            if (hideQuestionList) {
                // Récupérer toutes les questions et parcourir les questions chacun
                currentQuestions.forEach((value, index) => {
                    question_data[formData.selectedCriteria] = Array(value)
                    sendData2Server({
                        'user_id': localStorage.getItem(USER_ID_KEY),
                        'input_data': input_data,
                        'question_key_data': question_data,
                        'segment_key': formData.mailSegmentKey
                    })
                })
                setRunLoader(true)
            } else {
                // Uniquement la question chosie parmi la liste
                question_data[formData.selectedCriteria] = currentQuestions.filter(
                    (q: any) => q.question === formData.selectedQuestion
                );

                sendData2Server({
                    'user_id': localStorage.getItem(USER_ID_KEY),
                    'input_data': input_data,
                    'question_key_data': question_data,
                    'segment_key': formData.mailSegmentKey
                })
                setRunLoader(true)
            }
        }
    };

    const handleFeedback = (responseChoosed: boolean, idx: number) => {
        const isFocused = document.activeElement === feedBackContainerRef.current;
        // Restaurer le focus après la mise à jour
        setTimeout(() => {
            if (isFocused) {
                resultRef.current = null
                feedBackContainerRef.current?.focus();
            }
        }, 0);

        // Mettre à jour le loader associé au champ de feedback touché (like ou dislike)
        setRunFeedbackLoader((prevState) =>
            prevState.map((item, index) => (index === idx ? true : item))
        );
        // Vérification de la véraction de la prédiction
        let responseValidity = ""
        if (responseChoosed) {
            // La personne a validée la réponse fournie par le model, donc on la garde
            responseValidity = evaluationResults[idx]['réponse']
        } else {
            // Si la réponse est 'Oui' mais le feedback est 'false', on change la valeur par 'Non'. Et vice-versa
            responseValidity = evaluationResults[idx]['réponse'] === "Oui" ? "Non" : "Oui"
        }

        const data2send = {
            text: evaluationResults[idx]['prompt'],
            label: responseValidity,
            feedback_value: true,
            question: evaluationResults[idx]['question'],
            mail_segment: evaluationResults[idx]['mail_segment'],
            criteria: evaluationResults[idx]['criteria'],
            priority: `priorite_${formData.priorite}`
        }

        updateScoringData4Feedback(data2send).then((rep: any) => {
            const response_data_body = rep.data.body;

            if (response_data_body) {
                evaluationResults.forEach((value, index) => {
                    if (index === idx) {
                        let temp = [...evaluationResults];  // Create a new array to avoid direct mutation

                        temp[index]['réponse'] = responseValidity
                        if (response_data_body['status'] === "success") {
                            temp[index]['feedback_status'] = true
                        } else {
                            temp[index]['feedback_status'] = false
                        }

                        setEvaluationResults(temp)
                    }
                })
            }

            // Mettre à jour le loader associé au champ de feedback touché (like ou dislike) en false
            setRunFeedbackLoader((prevState) =>
                prevState.map((item, index) => (index === idx ? false : item))
            );
        }).catch((error) => {
            console.error("Erreur lors de l'envoi :", error);
            // Mettre à jour le loader associé au champ de feedback touché (like ou dislike) en false
            setRunFeedbackLoader((prevState) =>
                prevState.map((item, index) => (index === idx ? false : item))
            );
        });
    };

    const getRecipientDataByPersonName = () => {

    }

    // Ajout et modification des catégories, sous-catégories, questions
    const [finalNewAddCatAndSub, setFinalNewAddCatAndSub] = useState({
        newCategory: "",
        newCriteria: "",
        newPostType: "",
        newMailSegment: "",
        newSubCategory: "",
    })

    // Fonction pour trouver la clé à partir de la valeur
    function getKeyByValue(value: string): string | undefined {
        return Object.keys(mailSegmentDico).find(key => mailSegmentDico[key] === value);
    }

    /**
     * Ajouter une catégorie, sous-catégories, et les questions
     */
    const saveAddNewCatAndSubAndQuestion = () => {
        setModal2show("add")

        // La sous-catégorie remplie à la main sans choix
        let final_subCategory = inputValue
        // Si l'utilisateur a choisi une sous-catégorie parmi l'existente
        if (selectedTag && typeof selectedTag === "string") final_subCategory = selectedTag

        // Transformation en nouvelle liste
        const formattedQuestionList = fields.map(item => {
            // Filtrer les clés où la valeur est true
            const trueKeys = Object.keys(item.checks)
                .filter(key => item.checks[key] === true);

            return {
                question: item.value,
                key: trueKeys
            };
        });

        // Récupération de la clé du segment de mail
        const final_mailSegmentKey = finalNewAddCatAndSub.newMailSegment

        // Récupération des critères
        const final_criteria = finalNewAddCatAndSub.newCriteria

        // Construction de l'objet data2send
        const data2send: any = {}
        data2send['data'] = {
            [final_subCategory]: {
                [final_mailSegmentKey as string]: {
                    [final_criteria]: formattedQuestionList
                }
            }
        }
        data2send['db_name'] = finalNewAddCatAndSub.newPostType
        data2send['collection_name'] = finalNewAddCatAndSub.newCategory
        console.log(data2send)
        saveNewCatAndSubWithQuestion(data2send).then((response) => {
            if (response && response.data && response.data.body) {
                localStorage.removeItem(finalNewAddCatAndSub.newPostType)
                window.location.reload()
            }
        }).catch((e) => console.log(e))
    }

    /**
     * Modifier une catégorie, sous-catégorie, une ou des questions
     */
    const saveUpdateCatAndSubAndQuestion = () => {
        setModal2show("update")
    }

    const modalViewer = () => {
        switch (modal2show) {
            case "add":
                return displayAddModalView()
            case "update":
                return displayUpdateModalView()
            default:
                return <></>
        }
    }

    /**
     * Handle new category, subcategory, questions
     * @param e 
     */
    const handleAddNewCatAndSubHTML = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if(name === "newPostType") {}
        if(name === "newCriteria") {

        }
        setFinalNewAddCatAndSub(prev => ({ ...prev, [name]: value.trim() }))
    }

    const handleAddNewCatAndSubSelect = (
        e: SelectChangeEvent
    ) => {
        const { name, value } = e.target;
        setFinalNewAddCatAndSub(prev => ({ ...prev, [name]: value.trim() }))
    }

    // On stocke des objets avec un id unique plutôt que directement les JSX elements
    const [fields, setFields] = useState<{ id: number; value: string; checks: { [key: string]: boolean } }[]>([]);
    const nextIdRef = useRef(0);

    // Liste des noms des cases à cocher
    const checkboxNames = [
        'accroche',
        'company',
        'company_domain_activity',
        'mail_context',
        'name',
        'person_job',
        'service_value',
        'transition',
    ];

    const addTextField = () => {
        // Initialisation des cases à cocher à false pour le nouveau champ
        const initialChecks = checkboxNames.reduce((acc, name) => ({ ...acc, [name]: false }), {});
        setFields(prevFields => [...prevFields, { id: nextIdRef.current++, value: '', checks: initialChecks }]);
    };

    const removeTextField = (idToRemove: number) => {
        setFields(prevFields => {
            // Filtrer pour supprimer le champ avec l'ID correspondant
            const updatedFields = prevFields.filter(field => field.id !== idToRemove);
            return updatedFields;
        });
    };

    const handleCheckboxChange = (fieldId: number, name: string, checked: boolean) => {
        setFields(prevFields =>
            prevFields.map(field =>
                field.id === fieldId ? { ...field, checks: { ...field.checks, [name]: checked } } : field
            )
        );
    };

    /**
     * Nouvelle sous-catégories
     */
    const [storedSubCategories, setStoredSubCategories] = useState<string[]>([])

    // État pour la valeur de l'input
    const [inputValue, setInputValue] = useState('');
    // État pour les tags sélectionnés (array pour mode multiple, string pour mode single)
    const [selectedTag, setSelectedTag] = useState<string | string[]>([]);
    // État pour le mode single tag
    const [isSingleTagMode, setIsSingleTagMode] = useState(true);

    // Gestion de la sélection ou de l'ajout d'une sous-catégorie
    const handleSelection = (event: React.SyntheticEvent, value: string | null) => {
        if (value) {
            if (isSingleTagMode) {
                setSelectedTag(value);
            } else {
                setSelectedTag((prev) => {
                    // Vérifier le type de prev pour éviter l'erreur
                    if (Array.isArray(prev)) {
                        return prev.includes(value) ? prev : [...prev, value];
                    }
                    return [prev, value]; // Si prev est une string, créer un tableau
                });
            }
            setInputValue('');
        }
    };

    // Gestion de la suppression d'un tag
    const handleDeleteTag = (tagToDelete: string) => {
        if (isSingleTagMode) {
            // Mode single : supprime le tag et vide le champ
            setSelectedTag('');
            setInputValue('');
        } else {
            // Mode multiple : supprime uniquement le tag cliqué
            setSelectedTag((prev) => (Array.isArray(prev) ? prev.filter((tag) => tag !== tagToDelete) : prev));
        }
    };

    // Gestion du changement de mode
    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMode = event.target.checked;
        setIsSingleTagMode(newMode);
        // Réinitialiser les tags en mode single avec le premier tag s'il existe
        if (newMode && Array.isArray(selectedTag) && selectedTag.length > 0) {
            setSelectedTag(selectedTag[0]);
        } else if (!newMode && typeof selectedTag === 'string') {
            setSelectedTag(selectedTag ? [selectedTag] : []);
        }
    };

    const AutoCompleteSubCategoryView = () => {
        return (
            <Box sx={{ width: '100%', mx: 'auto', my: 2 }}>
                {/* Switch pour activer/désactiver le mode single tag */}
                {/* <FormControlLabel
                    control={<Switch checked={isSingleTagMode} onChange={handleModeChange} />}
                    label="Mode unique (remplace le tag précédent)"
                    sx={{ mb: 2 }}
                /> */}

                {/* Autocomplete avec TextField intégré */}
                <Autocomplete
                    freeSolo
                    options={storedSubCategories}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    onChange={handleSelection}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="outlined-basic"
                            label="Sous-catégorie associée"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={1}
                            placeholder="Analyses et réflexions,..."
                            name="newSubCategory"
                            onChange={handleAddNewCatAndSubHTML}
                        />
                    )}
                    sx={{ mb: 2 }}
                />

                {/* Affichage des tags sélectionnés */}
                {(Array.isArray(selectedTag) ? selectedTag.length > 0 : selectedTag !== '') && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            mt: 1,
                            p: 1,
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                            backgroundColor: '#f5f5f5',
                        }}
                    >
                        {isSingleTagMode && typeof selectedTag === 'string' ? (
                            <Chip
                                key={selectedTag}
                                label={selectedTag}
                                onDelete={() => handleDeleteTag(selectedTag)}
                                sx={{
                                    borderRadius: '16px',
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#115293' },
                                    '& .MuiChip-deleteIcon': {
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        '&:hover': { color: 'white' },
                                    },
                                }}
                            />
                        ) : (
                            Array.isArray(selectedTag) &&
                            selectedTag.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    onDelete={() => handleDeleteTag(tag)}
                                    sx={{
                                        borderRadius: '16px',
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                        '&:hover': { backgroundColor: '#115293' },
                                        '& .MuiChip-deleteIcon': {
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            '&:hover': { color: 'white' },
                                        },
                                    }}
                                />
                            ))
                        )}
                    </Box>
                )}
            </Box>
        );
    }
    /** Fin sous-catégories */

    const displayAddModalView = () => {
        return (
            <Modal
                keepMounted
                open={openAddModal}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography style={{ color: "black" }} id="keep-mounted-modal-title" variant="h6" component="h4">
                        Ajout de nouvelles catégories, sous-catégories, et questions
                    </Typography>
                    <div>
                        <div style={{ width: '100%', height: 1, background: 'black', marginBottom: 25 }}></div>
                        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
                            <div>
                                <Typography color="primary" variant="h6" component="h4">
                                    Classification de post
                                </Typography>

                                <div>
                                    {/* <Typography color="primary" variant="h6" component="h4">
                                        Type de post
                                    </Typography> */}
                                    <TextField id="outlined-basic" label="Type de post" variant="outlined" fullWidth multiline rows={1} defaultValue={""}
                                        placeholder="Post publié, Post partagé,..."
                                        name="newPostType"
                                        onChange={handleAddNewCatAndSubHTML}
                                    />
                                    {/* <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="newPostType"
                                            value={finalNewAddCatAndSub.newPostType}
                                            onChange={handleAddNewCatAndSubSelect}
                                        >
                                            {categories[formData.priorite]?.map((cat, index) => (
                                                <MenuItem key={index} value={cat} >
                                                    {cat}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl> */}
                                </div>

                                <TextField id="outlined-basic" label="Nouvelle catégorie" variant="outlined" fullWidth multiline rows={1} defaultValue={""}
                                    placeholder="Contenu personnel, Contenu professionnel"
                                    name="newCategory"
                                    onChange={handleAddNewCatAndSubHTML}
                                />

                                {/* <TextField id="outlined-basic" label="Sous-catégorie associé" variant="outlined" fullWidth multiline rows={1} defaultValue={""}
                                    placeholder="Analyses et réflexions,..."
                                    name="newSubCategory"
                                    onChange={handleAddNewCatAndSubHTML}
                                /> */}

                                {AutoCompleteSubCategoryView()}

                                <div>
                                    {/* <Typography color="primary" variant="h6" component="h4">
                                        Critère d'évaluation du segment de mail
                                    </Typography> */}
                                    <TextField id="outlined-basic" label="Critère d'évaluation du segment de mail" variant="outlined" fullWidth multiline rows={1} defaultValue={""}
                                        placeholder="Pertinence, Clarté..."
                                        name="newCriteria"
                                        onChange={handleAddNewCatAndSubHTML}
                                    />
                                </div>

                                {/* <TextField id="outlined-basic" label="Segment de mail" variant="outlined" fullWidth multiline rows={1} defaultValue={""}
                                    placeholder="Accroche, Transition, Proposition de valeur"
                                    name="newMailSegment"
                                    onChange={handleAddNewCatAndSubHTML}
                                /> */}
                                <div>
                                    <Typography color="primary" variant="h6" component="h4">
                                        Segment de mail
                                    </Typography>
                                    <FormControl fullWidth sx={{ marginLeft: 1 }}>
                                        <InputLabel id="demo-simple-select-label"></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="newMailSegment"
                                            value={finalNewAddCatAndSub.newMailSegment}
                                            onChange={handleAddNewCatAndSubSelect}
                                        >
                                            {mailSegmentKeys.map((cat) => (
                                                <MenuItem key={cat} value={cat} >
                                                    {mailSegmentDico[cat]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                    <Typography color="primary" variant="h6" component="h4" sx={{ display: 'flex', alignItems: "start" }}>
                                        Ajouter les questions
                                    </Typography>
                                    <Tooltip title="Ajouter une nouvelle question">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={addTextField}
                                            sx={{ minWidth: 20, maxHeight: 40, display: 'flex', alignItems: "center" }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Tooltip>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    {fields.map((field, index) => (
                                        <Box key={field.id} sx={{ display: 'flex', flexDirection: 'column' }} >
                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                <TextField
                                                    id={`outlined-multiline-static-${field.id}`}
                                                    label={`Question ${index + 1}`}
                                                    fullWidth
                                                    multiline
                                                    rows={1}
                                                    value={field.value}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        const newValue = event.target.value;
                                                        setFields(prevFields =>
                                                            prevFields.map(f =>
                                                                f.id === field.id ? { ...f, value: newValue } : f
                                                            )
                                                        );
                                                    }}
                                                />
                                                <Tooltip title="Retirer la question">
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        size="small"
                                                        sx={{ minWidth: 20, maxHeight: 40 }}
                                                        onClick={() => removeTextField(field.id)}
                                                    >
                                                        <RemoveIcon />
                                                    </Button>
                                                </Tooltip>
                                            </div>

                                            <div style={{ marginTop: 5, marginBottom: 5 }}>
                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                    Variable de contexte
                                                </Typography>
                                                {checkboxNames.map(name => (
                                                    <Chip
                                                        key={name}
                                                        label={
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={field.checks[name]}
                                                                        onChange={(e) => handleCheckboxChange(field.id, name, e.target.checked)}
                                                                        size="small"
                                                                        icon={<RadioButtonUncheckedIcon sx={{ color: '#757575' }} />}
                                                                        checkedIcon={<CheckCircleIcon sx={{ color: '#35a641' }} />}
                                                                        sx={{
                                                                            '&:hover': {
                                                                                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label={name.replace('_', ' ')} // Remplace les underscores par des espaces pour un affichage plus propre
                                                                sx={{ m: 0, color: field.checks[name] ? '#35a641' : '#424242' }}
                                                            />
                                                        }
                                                        sx={{
                                                            borderRadius: '16px',
                                                            backgroundColor: field.checks[name] ? 'rgba(25, 118, 210, 0.1)' : '#e0e0e0',
                                                            border: `1px solid ${field.checks[name] ? '#1976d2' : '#bdbdbd'}`,
                                                            transition: 'all 0.3s ease',
                                                            '&:hover': {
                                                                backgroundColor: field.checks[name] ? 'rgba(25, 118, 210, 0.2)' : '#d5d5d5',
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        </Box>
                                    ))}
                                </div>
                            </div>

                            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 2, paddingBottom: 2 }}>
                                <ButtonGroup variant="outlined" sx={{ marginRight: 1 }}>
                                    <Button color='error' variant="outlined" disabled={ButtonLoader} onClick={handleClose}>Annuler</Button>
                                </ButtonGroup>

                                <ButtonGroup variant="outlined" sx={{ marginLeft: 1 }}>
                                    {
                                        ButtonLoader ? (
                                            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                                Enregistrement en cours
                                            </LoadingButton>
                                        ) : (
                                            <Button variant="outlined" onClick={saveAddNewCatAndSubAndQuestion}>Enregister</Button>
                                        )
                                    }
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </div>
                </Box>
            </Modal>
        )
    }

    const displayUpdateModalView = () => {
        return (
            <Modal
                keepMounted
                open={openAddModal}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography style={{ color: "black" }} id="keep-mounted-modal-title" variant="h6" component="h4">
                        Modification de catégories, sous-catégories et de questions
                    </Typography>
                    <div>
                        <div style={{ width: '100%', height: 1, background: 'black', marginBottom: 25 }}></div>
                        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
                            <div>
                                <TextField id="outlined-basic" label="Catégorie de post" variant="outlined" fullWidth multiline rows={1} defaultValue={formData.selectedCategory}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    }}
                                />
                                <TextField id="outlined-basic" label="Sous-catégorie du post" variant="outlined" fullWidth multiline rows={1} defaultValue={formData.selectedSubcategory}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    }}
                                />
                                <TextField id="outlined-multiline-static" label="Question" fullWidth multiline rows={1} defaultValue={formData.selectedQuestion}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    }}
                                />

                            </div>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonGroup variant="outlined">
                                    <Button color='error' variant="outlined" disabled={ButtonLoader} onClick={handleClose}>Annuler</Button>
                                    {
                                        ButtonLoader ? (
                                            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                                Mise à jour en cours
                                            </LoadingButton>
                                        ) : (
                                            <Button variant="outlined" onClick={() => { }}>Mettre à jour</Button>
                                        )
                                    }
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </div>
                </Box>
            </Modal>
        )
    }

    /**
     * Fermeture du modal
     */
    const handleClose = () => {
        setModal2show("none")
    }

    useEffect(() => {
        if (formData.mailSegmentKey && dataDict) {
            const questionData_ = questionsByCriteria(formData.mailSegmentKey, dataDict)

            setQuestionData(questionData_)

            const newCriteria = formData.selectedCriteria;
            const newQuestions = questionData_[newCriteria] || [];
            setCurrentQuestions(newQuestions);

            setFormData((prev) => ({
                ...prev,
                mailSegmentKey: formData.mailSegmentKey,  // Fixed: don't overwrite with criteria
                selectedCriteria: newCriteria,
                selectedQuestion: newQuestions.length > 0 ? newQuestions[0].question : ""
            }));
        }
    }, [dataDict, formData.mailSegmentKey])


    useEffect(() => {
        if (evaluationResults.length > 0) {
            resultRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [evaluationResults]);

    useEffect(() => {
        /**Vérifier si le résultat de l'évaluation est encore en cours ou non */
        if (evaluationResults.length > 0 && currentQuestions.length !== evaluationResults.length) {
            setEvalResultProcess(true)
        } else setEvalResultProcess(false)

        // Réactiver le bouton envoi
        if (currentQuestions.length !== evaluationResults.length) setEvalResultProcess(false)
    }, [evaluationResults.length, currentQuestions.length])

    useEffect(() => {
        /**Récupérer les noms de collections dans la BDD déjà stocké */
        getCollectionName4BartEvaluation().then((rep: any) => {
            const body = rep.data.body;
            if (body) {
                // Cast explicite pour informer TypeScript que chaque `category` est un objet de string[]
                const allElements: string[] = Object.values(body)
                    .flatMap(category => Object.values(category as Record<string, string[]>)) // Cast en Record<string, string[]>
                    .flat(); // Aplatir le tableau

                setStoredSubCategories(allElements)
            }
        }).catch((e) => console.log(e));


    }, [])


    return (
        <div className={`bart-email-form-wrapper ${showResult ? "email-form-split" : "email-form-center"}`}>
            <div className="email-form-container">
                <div className="email-form-header">
                    <h2>BART : Formulaire d'évaluation de mail</h2>
                </div>

                <div className="email-form-content">
                    <section className="email-form-section">
                        <h4>Mail</h4>
                        <textarea
                            name="fullMail"
                            value={formData.fullMail}
                            onChange={handleChange}
                            className="email-form-textarea"
                            placeholder="Saisissez le mail entier..."
                            rows={10}
                        />
                    </section>

                    <section className="email-form-section">
                        <div style={{ display: "flex", flex: 1 }}>
                            <h4 style={{ display: "flex", flex: 1 }}>Destinataire</h4>
                            <div style={{ display: "flex" }}>
                                <input
                                    // disabled={hideAll}
                                    type="checkbox"
                                    style={{ margin: 5, appearance: "auto", width: 25, accentColor: "rgb(55, 149, 65)" }}
                                    onChange={getRecipientDataByPersonName}
                                />
                                <p style={{ display: 'flex', color: "rgb(219, 219, 219)", fontWeight: 'bold' }}>
                                    Remplir tous les champs
                                </p>
                            </div>
                        </div>
                        <input
                            name="person_job"
                            value={formData.person_job}
                            onChange={handleChangeRecipient}
                            className="email-form-input"
                            placeholder="Poste de la personne cible"
                        />
                        <input
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChangeRecipient}
                            className="email-form-input"
                            placeholder="Nom de l'entreprise"
                        />
                        <input
                            name="target_company_value"
                            value={formData.target_company_value}
                            onChange={handleChangeRecipient}
                            className="email-form-input"
                            placeholder="Valeur de l'entreprise cible"
                        />
                        <input
                            name="target_company_activity"
                            value={formData.target_company_activity}
                            onChange={handleChangeRecipient}
                            className="email-form-input"
                            placeholder="Activité/Secteur de l'entreprise cible"
                        />
                    </section>

                    <section className="email-form-section">
                        <h4>Émetteur</h4>
                        <textarea
                            name="sender_value_proposition"
                            value={formData.sender_value_proposition}
                            onChange={handleChange}
                            className="email-form-textarea"
                            placeholder="Proposition de valeur de l'entreprise émetteur"
                            rows={4}
                        />
                    </section>

                    <section className="email-form-section">
                        <h4>Priorité</h4>
                        <select
                            name="priorite"
                            value={formData.priorite}
                            onChange={handleChange}
                            className="email-form-select"
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>

                        <h4>Type</h4>
                        <select
                            name="postCategory"
                            value={formData.postCategory}
                            onChange={handleChange}
                            className="email-form-select"
                        >
                            {categories[formData.priorite]?.map((cat, index) => (
                                <option key={index} value={cat}>{cat}</option>
                            ))}
                        </select>
                    </section>

                    <section className="email-form-section">
                        <h4>Contexte (Post, description, info site internet)</h4>
                        <textarea
                            name="mailContext"
                            value={formData.mailContext}
                            onChange={handleChange}
                            className="email-form-textarea"
                            placeholder="Saisissez ici le contexte"
                            rows={6}
                        />
                    </section>

                    <section className="email-form-section">
                        <div style={{ display: "flex", flex: 1 }}>
                            <h4 style={{ display: "flex", flex: 1 }}>Catégories </h4>
                            <Box
                                sx={{
                                    display: 'flex',
                                    borderRadius: '20px', // Fond arrondi comme dans l'image
                                    backgroundColor: '#f5e7c7', // Couleur "jaunesombre" (beige clair)
                                    padding: '4px 8px', // Espacement interne
                                    gap: '8px', // Espacement entre les boutons
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Ombre subtile
                                }}
                            >
                                <Tooltip title="Ajouter des catégories...">
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setOpenAddModal(true);
                                            setModal2show('add');
                                        }}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px', // Espacement entre icône et texte
                                            backgroundColor: 'transparent',
                                            color: '#1976d2', // Bleu comme dans l'image
                                            textTransform: 'uppercase', // Texte en majuscules
                                            fontWeight: 'bold',
                                            padding: '6px 12px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(25, 118, 210, 0.1)', // Légère surbrillance au survol
                                            },
                                        }}
                                    >
                                        <ControlPointIcon sx={{ fontSize: '20px' }} />
                                        <Typography variant="body2" component="span">
                                            Ajouter
                                        </Typography>
                                    </Button>
                                </Tooltip>

                                <Tooltip title="Mettre à jour les catégories...">
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setUpdateModal(true);
                                            setModal2show('update');
                                        }}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                            backgroundColor: 'transparent',
                                            color: '#2e7d32', // Vert comme dans l'image
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            padding: '6px 12px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(46, 125, 50, 0.1)',
                                            },
                                        }}
                                    >
                                        <UpdateIcon sx={{ fontSize: '20px' }} />
                                        <Typography variant="body2" component="span">
                                            Modifier
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </Box>
                        </div>

                        <select
                            name="selectedCategory"
                            className="email-form-select"
                            value={formData.selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value="" disabled>Choisir une catégorie</option>
                            {Object.keys(postCategoryOptions[formData.postCategory]).map((category) => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>

                        <h4>Sous-catégories </h4>
                        <select
                            name="selectedSubcategory"
                            className="email-form-select"
                            value={formData.selectedSubcategory}
                            onChange={handleCategoryChange}
                            disabled={!formData.selectedCategory}
                        >
                            <option value="" disabled>Choisir une sous-catégorie</option>
                            {
                                formData.selectedCategory && postCategoryOptions[formData.postCategory] && postCategoryOptions[formData.postCategory][formData.selectedCategory] &&
                                postCategoryOptions[formData.postCategory][formData.selectedCategory].map((subcategory) => (
                                    <option key={subcategory} value={subcategory}>{subcategory}</option>
                                ))}
                        </select>
                    </section>

                    <section className="email-form-section">
                        <h4>Segment de mail</h4>
                        <select
                            name="mailSegmentKey"
                            value={formData.mailSegmentKey}
                            onChange={handleMailSegmentChange}
                            className="email-form-select"
                        >
                            <option value="accroche">Accroche</option>
                            <option value="transition">Transition</option>
                            <option value="service_value">Proposition de valeur</option>
                        </select>
                    </section>

                    <section className="email-form-section">
                        <h4>Critère d'évaluation</h4>
                        <select
                            name="selectedCriteria"
                            value={formData.selectedCriteria}
                            onChange={handleCriteriaChange}
                            className="email-form-select"
                        >
                            {Object.entries(criteriaOptions[formData.mailSegmentKey] || {}).map(
                                ([key, label]) => (
                                    <option key={key} value={key}>{label}</option>
                                )
                            )}
                        </select>
                    </section>

                    <section className="email-form-section">
                        <h4>Questions</h4>
                        <select
                            name="selectedQuestion"
                            value={formData.selectedQuestion}
                            onChange={handleChange}
                            className="email-form-select"
                            disabled={hideQuestionList}
                        >
                            {
                                currentQuestions.map(
                                    (q: any, index: number) => (
                                        <option key={index} value={q.question}>{`Q${index + 1} : ${q.question}`}</option>
                                    )
                                )
                            }
                        </select>
                        <div style={{ display: "flex" }}>
                            <input
                                type="checkbox"
                                style={{ appearance: "auto", width: 30 }}
                                onChange={disableAllquestions}
                            />
                            <p>Toutes les questions</p>
                        </div>
                    </section>
                </div>

                {runLoader ? (
                    <div className="email-form-footer">
                        <div className="email-form-loader">
                            <LoaderResponse colorValue={COLORS.jauneClaire} w={20} h={20} />
                            <p>Envoi en cours...</p>
                        </div>
                    </div>
                ) : (
                    <div className="email-form-footer">
                        <button
                            style={{ cursor: evalResultProcess ? 'wait' : 'pointer' }}
                            disabled={evalResultProcess}
                            type="submit"
                            onClick={handleSubmit}
                            className="email-form-button">
                            Envoyer
                        </button>
                    </div>
                )}
                {modal2show !== "none" && modalViewer()}
            </div>

            {/* Section Résultats - 1/3 de l'écran */}
            {evaluationResults.length > 0 && (
                <div ref={resultRef} className="email-result-container">
                    <h2 className="email-result-title">Résultats de l'évaluation</h2>
                    {showResult && evaluationResults.length > 0 ? (
                        <ul className="email-result-list">
                            {
                                evaluationResults.map((res, index) => (
                                    <li key={index} className="email-result-item" style={
                                        !res.feedback_status ? { background: "#ffffff30" }
                                            : { background: res.réponse === "Oui" ? "rgba(33, 180, 45, 0.4)" : res.réponse === "Non" ? "#fb000066" : "white" }
                                    }>
                                        <p className="email-result-question">{String(res.question).replace("Question:", `Question ${index + 1} : `)}</p>
                                        <AccordionComponent
                                            detailedDataItems={getContextDataFromList(String(res.question).replace("Question:", ""))}
                                        />
                                        <p style={{ fontSize: 'small' }}>Segment: <span className="email-result-answer">{mailSegmentValue[res.mail_segment]}</span></p>
                                        <p style={{ fontSize: 'small' }}>Critère: <span className="email-result-answer">{criteriaOptions[res.mail_segment][res.criteria]}</span></p>
                                        <p style={{ fontSize: 'small' }}>Réponse: <span className="email-result-answer" style={{ color: res.réponse === "Oui" ? "green" : "red" }}>
                                            {res.réponse}
                                        </span>
                                        </p>

                                        {
                                            !res.feedback_status && runFeedbackLoader[index] ? <div className="email-form-loader">
                                                <LoaderResponse colorValue={COLORS.grisClaire} w={20} h={20} />
                                                <p>validation en cours...</p>
                                            </div>
                                                : (
                                                    !res.feedback_status ?
                                                        <div ref={feedBackContainerRef} className="mt-4 feedback-container">
                                                            <p className="text-sm text-gray-600 mb-2">Cette réponse vous semble-t-elle correcte ?</p>
                                                            <FeedbackChoice id={index} onFeedback={handleFeedback} statusActivation={evalResultProcess} />
                                                        </div>
                                                        : (
                                                            <div className="mt-4 feedback-container">
                                                                <p className="text-eval text-sm text-gray-600 mb-2">Déjà évalué</p>
                                                            </div>
                                                        )
                                                )
                                        }
                                    </li>
                                ))}
                        </ul>
                    ) : (
                        <p className="email-result-empty">Aucun résultat disponible</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default BartTestEmailForm;
