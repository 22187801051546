// type mailSegmentDict = {
//     accroche: string;
//     transition: string;
//     service_value: string;
// }

// Définition du type pour l'objet
interface mailSegmentDict {
    [key: string]: string;
}

export const mailSegmentDico: mailSegmentDict = {
    accroche : "Accroche",
    transition: "Transition",
    service_value: "Proposition de valeur"
}

// Extraire dynamiquement les clés et leur type
export const mailSegmentKeys = Object.keys(mailSegmentDico) as (keyof typeof mailSegmentDico)[];
// type MailSegmentKey = keyof typeof mailSegmentDico; // "accroche" | "transition" | "service_value"